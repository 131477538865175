import React from 'react'
import { observer } from 'mobx-react'
import { Box, Typography, Grid } from '@mui/material'
import './UserDetails.css'
import Store from '../../../Store/Store'
import { useTranslation } from 'react-i18next'

import CompletedTrainingTable from './Tables/CompletedTrainingTable'
import NotCompletedTrainingTable from './Tables/NotCompletedTrainingTable'

const UserDetails = observer(() => {
  const { t } = useTranslation()

  return (
    <div>
      <Box sx={{ p: '10px 20px' }}>
        <div className="detail-row">
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
            {t("Username")}:
          </Typography>
          <Typography variant="subtitle2">{Store.accountInfo.currentUser.name}</Typography>
        </div>
        <div className="detail-row">
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
            {t("Userrole")}:
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: '12px' }}>
            {Store.accountInfo.userRole}
          </Typography>
        </div>

        {/* <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px', mt: '18px' }}>
          User training:
        </Typography> */}
      </Box>
      <Box sx={{ mb: '14px' }}>
        <Grid container direction="row" justifyContent="space-between" sx={{ marginLeft: '0px' }}>
          <Grid item sx={{ maxWidth: '48%', mr: '10px' }}>
            <CompletedTrainingTable />
          </Grid>
          <Grid item sx={{ maxWidth: '48%' }}>
            <NotCompletedTrainingTable />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
})

export default UserDetails
