import { makeAutoObservable } from 'mobx'
// import { makePersistable, clearPersistedStore } from 'mobx-persist-store'
import AccountInfoStore from './AccountInfoStore'
import RowDataStore from './RowDataStore'
import ReportDataStore from './ReportDataStore'
import OnboardingDataStore from './OnboardingDataStore'
import ProvisioningDataStore from './ProvisioningDataStore'
import UserProfileStore from './UserProfileStore'
import TestingDataStore from './TestingDataStore'

class Store {
  // observables
  openModal = false
  modalTitle = ''
  accountInfo = new AccountInfoStore()
  rowData = new RowDataStore()
  reportData = new ReportDataStore()
  onboardingStore = new OnboardingDataStore()
  provisioningDataStore = new ProvisioningDataStore()
  userProfileStore = new UserProfileStore()
  testingDataStore = new TestingDataStore()
  workstationName = ''
  // Snackbar stuff
  openSnackbar = false
  severity = 'error'
  message = ''

  constructor() {
    makeAutoObservable(this)
  }

  // action
  setOpenModal = openModal => {
    this.openModal = openModal
    // console.log(openModal)
  }

  setModalTitle = modalTitle => {
    this.modalTitle = modalTitle
  }

  setWorkstationName = workstationName => {
    this.workstationName = workstationName
  }

  setOpenSnackbar = openSnackbar => {
    this.openSnackbar = openSnackbar
  }

  setSeverity = severity => {
    this.severity = severity
  }

  setMessage = message => {
    this.message = message
  }

  // actions change observables!
}

// This is exporting a singleton, one instance of a Store
export default new Store()
