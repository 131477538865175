import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Container, Paper, Grid, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import RUTOnboarded from './RUTOnboarded'
import RobotNotFound from './RobotNotFound'
import Error2 from './Error2'

const TestModalScreens = () => {
  const [openRUTModal, setOpenRUTModal] = useState(false)
  const [openRobotModal, setOpenRobotModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()


  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleOk = () => {
    history.push('/app')
  }

  const handleOpenModalRUT = () => setOpenRUTModal(true)
  const handleRUTClose = () => setOpenRUTModal(false)

  const handleOpenRobotModal = () => setOpenRobotModal(true)
  const handleRobotModalClose = () => setOpenRobotModal(false)

  const handleOpenErrorModal = () => setOpenErrorModal(true)
  const handleCloseErrorModal = () => setOpenErrorModal(false)

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("TestModalScreens")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px', m: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '29px' }}>
              <Button variant="contained" onClick={handleOpenModalRUT}>
                {t("RUTalreadyonboarded")}
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '29px' }}>
              <Button variant="contained" onClick={handleOpenRobotModal}>
                {t("RobotNotFound")}
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '29px' }}>
              <Button variant="contained" onClick={handleOpenErrorModal}>
                {t("Error2")}
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '29px' }}>
              <Button variant="contained" onClick={handleOk}>
                {t("OK")}
              </Button>
            </div>
          </Grid>
          <RUTOnboarded open={openRUTModal} handleClose={handleRUTClose} />
          <RobotNotFound open={openRobotModal} handleClose={handleRobotModalClose} />
          <Error2 open={openErrorModal} handleClose={handleCloseErrorModal} />
        </Paper>
      </Container>
    </Box>
  )
}

export default TestModalScreens
