import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Button, Box, InputBase, IconButton, styled, alpha, MenuItem, Menu } from '@mui/material'
import { Search, AccountCircle } from '@mui/icons-material'
import { ReactComponent as NavLogo } from '../../Icons/navLogo.svg'
import MenuPopupState from './Dropdown/MenuPopupState'
import UserMenu from './Dropdown/UserMenu'
import { useTranslation } from 'react-i18next'

const SearchDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#0000001F', 0.15),
  '&:hover': {
    backgroundColor: alpha('#0000001F', 0.2),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  width: `min(100%, 480px)`,
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}))

const NavBar = () => {
  const [anchorElement, setAnchorElement] = useState(null)
  const [mobileAnchorElement, setMobileAnchorElement] = useState(null)

  const { authState, oktaAuth } = useOktaAuth()
  const history = useHistory()

  const isMenuOpen = Boolean(anchorElement)
  const isMobileMenuOpen = Boolean(mobileAnchorElement)
  const { t } = useTranslation()

  const handleProfileMenuOpen = event => {
    setAnchorElement(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileAnchorElement(null)
  }

  const handleMenuClose = () => {
    setAnchorElement(null)
    handleMobileMenuClose()
  }

  const handleProfileClick = () => {
    setAnchorElement(null)
    handleMobileMenuClose()
    history.push('/app/user')
  }

  const handleRouteChange = route => {
    history.push(`${route}`)
  }

  const login = async () => history.push('/login')

  // Add rotating screen in the middle when Logout is clicked to indicate
  // that something is happening and that you are getting logged out
  const logout = async () => {
    oktaAuth.tokenManager.clear()

    try {
      await oktaAuth.signOut()
    } catch (err) {
      console.log('Sing out error: ' + err)
    }
  }

  if (!authState) {
    return null
  }

  // const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorElement}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'right',
      // }}
      // id={menuId}
      // keepMounted
      // transformOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'right',
      // }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {authState.isAuthenticated && <MenuItem onClick={handleProfileClick}>{t("Profile")}</MenuItem>}
      {authState.isAuthenticated && <MenuItem onClick={logout}>{t("LogOut")}</MenuItem>}
      {!authState.isPending && !authState.isAuthenticated && <MenuItem onClick={login}>{t("LogIn")}</MenuItem>}
    </Menu>
  )

  // For mobile
  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileAnchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button color="inherit">{t("AssetTracking")}</Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit">{t("Onboarding")}</Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit">{t("Testing")}</Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit">{t("Repairs")}</Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit">{t("Evaluation")}</Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit">{t("Reporting")}</Button>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{t("Profile")}</p>
      </MenuItem>
    </Menu>
  )

  return (
    <AppBar>
      <Toolbar>
        <Box>
          <IconButton disabled={!authState.isAuthenticated} onClick={() => history.push('/app')}>
            <NavLogo />
          </IconButton>
        </Box>
        {authState.isAuthenticated && (
          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
              justifyContent: 'space-between',
              whiteSpace: 'nowrap',
            }}
          >
            <MenuPopupState title="Asset Tracking" />
            <Button color="inherit" sx={{ px: 2 }} onClick={() => handleRouteChange('/app/provisioning')}>
              {t("Provisioning")}
            </Button>
            <MenuPopupState title="Onboarding" />
            <Button color="inherit" sx={{ px: 2 }} onClick={() => handleRouteChange('/app/testing')}>
              {t("Testing")}
            </Button>
            <Button color="inherit" sx={{ px: 2 }} onClick={() => handleRouteChange('/app/repairs')}>
              {t("Repairs")}
            </Button>
            <Button color="inherit" sx={{ px: 2 }} style={{ display: 'none' }}>
              {t("Evaluation")}
            </Button>
            <Button color="inherit" sx={{ px: 2 }} style={{ display: 'none' }}>
              {t("Reporting")}
            </Button>
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <SearchDiv>
          <SearchIconWrapper>
            <Search />
          </SearchIconWrapper>
          <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
        </SearchDiv>
        <UserMenu logout={logout}>
          <AccountCircle />
        </UserMenu>
        {/* <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
          onClick={handleProfileMenuOpen}
          aria-controls={menuId}
        >
          <AccountCircle />
        </IconButton> */}
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </AppBar>
  )
}

export default NavBar
