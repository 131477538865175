import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  FormControl,
  TextField,
  // InputLabel,
  // MenuItem,
  // Select,
  Button,
} from '@mui/material'
import { useTranslation } from 'react-i18next'


const AddBattery = () => {
  const [serial, setSerial] = useState('')
  // const [type, setType] = useState('Las Vegas')
  // const [variant, setVariant] = useState('V2')
  const [canSubmit, setCanSubmit] = useState(true)
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()


  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // const handleTypeChange = event => {
  //   setType(event.target.value)
  // }

  // const handleVariantChange = event => {
  //   setVariant(event.target.value)
  // }

  const handleSubmit = () => {
    history.push(window.location.pathname + '/success')
  }

  const handleCancel = () => {
    history.push('/')
  }

  const handleInputChange = value => {
    setSerial(value)
    if (value !== '') {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("OnboardABattery")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" justifyContent="space-evenly" sx={{ p: '35px' }}>
            <div className="battery-row">
              <Typography variant="subtitle1" component="div">
                {t("BatterySN")}
              </Typography>
              <Box sx={{ minWidth: '350px' }}>
                <FormControl fullWidth>
                  <TextField
                    required
                    id="outlined"
                    label="Enter Serial Number"
                    value={serial}
                    onChange={e => handleInputChange(e.target.value)}
                  />
                </FormControl>
              </Box>
            </div>
            {/* <div className="battery-row">
              <Typography variant="subtitle1" component="div">
                Battery Type
              </Typography>
              <Box sx={{ minWidth: '350px' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Type"
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={'Las Vegas'}>Las Vegas</MenuItem>
                    <MenuItem value={'Miami'}>Miami</MenuItem>
                    <MenuItem value={'Boston'}>Boston</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="battery-row">
              <Typography variant="subtitle1" component="div">
                Variant
              </Typography>
              <Box sx={{ minWidth: '350px' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Variant</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={variant}
                    label="Variant"
                    onChange={handleVariantChange}
                  >
                    <MenuItem value={'V1'}>V1</MenuItem>
                    <MenuItem value={'V2'}>V2</MenuItem>
                    <MenuItem value={'V3'}>V3</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div> */}
            <div className="buttons-center">
              <Button variant="contained" color="greyGreen" sx={{ margin: '0px 4px' }} onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Button variant="contained" sx={{ margin: '0px 4px' }} onClick={handleSubmit} disabled={canSubmit}>
                {t("Submit")}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}

export default AddBattery
