import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material'
import { BatteryFull, Battery80, Battery60, Battery50, Battery20, BatteryAlert } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import WorkstationTestbedDetail from '../WorkstationTestbedDetail'
import './RackTable.css'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  root: {
    '& .MuiTableCell-head': {
      background: '#6A768C 0% 0% no-repeat padding-box',
      color: 'white',
    },
  },
})

const RackTable = ({ rows }) => {
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (
      history.location.state?.from === 'new_battery' ||
      history.location.state?.from === 'bit' ||
      history.location.state?.from === 'error'
    ) {
      history.location.state.from = ''
      handleOpenModalDetail()
    }
  }, [history.location.state])

  const columns = [
    {
      id: 'location',
      label: t("Location"),
      minWidth: 100,
    },
    {
      id: 'status',
      label: t("RobotStatus"),
      minWidth: 150,
    },
    {
      id: 'battery',
      label: t("BatterySOC"),
      minWidth: 120,
    },
    {
      id: 'notifications',
      label: t("Notifications"),
      minWidth: 150,
    },
  ]

  const handleOpenModalDetail = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  const handleStartTest = () => {
    history.push('/app/testing/inspect_rut', { from: '/app/testing/workstation' })
  }

  const statusFormatter = value => {
    if (value === 'Running') {
      return (
        <span className="button-span">
          <Button variant="contained" color="lightGreen" disableElevation sx={{ width: '92px', height: '36px' }}>
            {value}
          </Button>
        </span>
      )
    } else if (value === 'Paused') {
      return (
        <span className="button-span">
          <Button variant="contained" color="lightOrange" disableElevation sx={{ width: '92px', height: '36px' }}>
            {value}
          </Button>
        </span>
      )
    } else if (value === 'Stopped') {
      return (
        <span className="button-span">
          <Button variant="contained" color="lightPurple" disableElevation sx={{ width: '92px', height: '36px' }}>
            {value}
          </Button>
        </span>
      )
    } else {
      return value
    }
  }

  const notificationsFormatter = (isUnderTest, value) => {
    if (!isUnderTest && value === '') {
      return (
        <Button variant="contained" sx={{ width: '121px', height: '36px' }} onClick={handleStartTest}>
          {t("StartTest")}
        </Button>
      )
    } else if (isUnderTest && value === '') {
      return value
    } else {
      if (value === 'User' || value === 'BiT' || value === 'Battery') {
        return (
          <span className="button-span">
            <Button variant="contained" color="lightOrange" disableElevation sx={{ width: '92px', height: '36px' }}>
              {value}
            </Button>
          </span>
        )
      } else {
        return (
          <span className="button-span">
            <Button variant="contained" color="lightPurple" disableElevation sx={{ width: '92px', height: '36px' }}>
              {value}
            </Button>
          </span>
        )
      }
    }
  }

  const batteryFormatter = value => {
    if (value <= 100 && value >= 81) {
      return (
        <span className="battery-format-span">
          <BatteryFull sx={{ color: '#6A768C' }} /> {value}%
        </span>
      )
    } else if (value <= 80 && value >= 61) {
      return (
        <span className="battery-format-span">
          <Battery80 sx={{ color: '#6A768C' }} /> {value}%
        </span>
      )
    } else if (value <= 60 && value >= 51) {
      return (
        <span className="battery-format-span">
          <Battery60 sx={{ color: '#6A768C' }} /> {value}%
        </span>
      )
    } else if (value <= 50 && value >= 21) {
      return (
        <span className="battery-format-span">
          <Battery50 sx={{ color: '#6A768C' }} /> {value}%
        </span>
      )
    } else if (value <= 20 && value >= 4) {
      return (
        <span className="battery-format-span">
          <Battery20 sx={{ color: '#6A768C' }} /> {value}%
        </span>
      )
    } else if (value <= 3 && (value > 0 || value === 0)) {
      return (
        <span className="battery-format-span">
          <BatteryAlert sx={{ color: '#6A768C' }} /> {value}%
        </span>
      )
    } else {
      return value
    }
  }

  return (
    <div>
      <TableContainer sx={{ maxHeight: 440, maxWidth: 'sm' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.root}>
              {columns.map(column => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              let boldText = ''
              const isUnderTest = row.status !== ''
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.location}
                  sx={{ cursor: 'pointer' }}
                  onClick={handleOpenModalDetail}
                >
                  {columns.map(column => {
                    let value = row[column.id]

                    if (column.id === 'notifications') {
                      value = notificationsFormatter(isUnderTest, value)
                    }

                    if (column.id === 'status') {
                      value = statusFormatter(value)
                    }

                    if (column.id === 'battery') {
                      value = batteryFormatter(value)
                    }
                    return (
                      <TableCell key={column.id} sx={{ fontWeight: `${boldText}` }}>
                        {value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <WorkstationTestbedDetail open={openModal} handleClose={handleClose} />
    </div>
  )
}

export default RackTable
