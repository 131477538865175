import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Paper,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { CompletedTraining } from '../../../../Store/MOCK_DATA'

const useStyles = makeStyles({
  headtable: {
    '& .MuiTableCell-head': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 20%)',
    },
  },
})

const CompletedTrainingTable = () => {
  const classes = useStyles()

  const dateFormater = cell => {
    // const date = new Date(cell);
    // console.log(JSON.stringify(date));
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleDateString()

    return formatedDate
  }

  const CustomToolbar = tableName => {
    const name = tableName.tableName

    return (
      <Toolbar sx={{ backgroundColor: '#6A768C', color: '#FFFFFFDE' }}>
        <Typography variant="h6" id="tableTitle" component="div">
          {name}
        </Typography>
      </Toolbar>
    )
  }
  // MOCK DATA
  let rows = CompletedTraining

  const columns = [
    {
      id: 'title',
      label: 'Title',
      minWidth: 160,
    },
    {
      id: 'completed',
      label: 'Date completed',
      minWidth: 150,
    },
    {
      id: 'exp_date',
      label: 'Expiration date',
      minWidth: 150,
    },
  ]

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3 }}>
      <CustomToolbar tableName="Completed Trainings" />
      <TableContainer sx={{ maxHeight: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.headtable}>
              {columns.map(column => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.title}>
                  {columns.map(column => {
                    let value = row[column.id]
                    if (column.id === 'completed' || column.id === 'exp_date') {
                      value = dateFormater(value)
                    }
                    return <TableCell key={column.id}>{value}</TableCell>
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
export default CompletedTrainingTable
