import React from 'react'
import ItemCard from '../Item Card/ItemCard'
import { Grid } from '@mui/material'
import { Hotel, Album, Memory, BatterySaver, Settings } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const Items = ({ title, fromModal }) => {
  const { t } = useTranslation()
  if (title === 'Asset Tracking') {
    return (
      <Grid container columnSpacing={3} direction="row" sx={{ mt: 1 }} justifyContent="center" alignItems="center">
        <Grid item>
          <ItemCard
            name="Test Beds"
            fromModal={fromModal}
            route="/app/testbed"
            icon={<Hotel style={{ color: '#87A28E' }} />}
          />
        </Grid>
        <Grid item>
          <ItemCard
            name="Robots"
            fromModal={fromModal}
            route="/app/robot"
            icon={<Album style={{ color: '#87A28E' }} />}
          />
        </Grid>
        <Grid item>
          <ItemCard
            name="Spare Parts"
            fromModal={fromModal}
            route="/app/spare_part"
            icon={<Memory style={{ color: '#87A28E' }} />}
          />
        </Grid>
      </Grid>
    )
  }
  if (title === 'Onboarding') {
    return (
      <Grid container columnSpacing={3} direction="row" sx={{ mt: 1 }} justifyContent="center" alignItems="center">
        <Grid item>
          {/* Removes Print Label item.
          If needed in the future, uncomment the ItemCard component and import Print icon from mui */}
          {/* <ItemCard
            name="Print Labels"
            fromModal={fromModal}
            route="/app/onboarding/print_label"
            icon={<Print style={{ color: '#EBC172' }} />}
          /> */}
        </Grid>
        <Grid item>
          <ItemCard
            name="Add Robot"
            fromModal={fromModal}
            route="/app/onboarding/robot"
            icon={<Album style={{ color: '#EBC172' }} />}
          />
        </Grid>
        <Grid item>
          <ItemCard
            name="Add Battery"
            fromModal={fromModal}
            route="/app/onboarding/battery"
            icon={<BatterySaver style={{ color: '#EBC172' }} />}
          />
        </Grid>
        <Grid item>
          <ItemCard
            name="Add-on Tests"
            fromModal={fromModal}
            route="/app/onboarding/addon_tests"
            icon={<Settings style={{ color: '#EBC172' }} />}
          />
        </Grid>
      </Grid>
    )
  }
  // If Repairs was modal:
  // if (title === 'Repairs') {
  //   return (
  //     <Grid container columnSpacing={3} direction="row" sx={{ mt: 1 }} justifyContent="center" alignItems="center">
  //       <Grid item>
  //         <ItemCard
  //           name="All Repairs"
  //           fromModal={fromModal}
  //           route="/app/repair/all"
  //           icon={<ManageSearch style={{ color: '#8291AD' }} />}
  //         />
  //       </Grid>
  //       <Grid item>
  //         <ItemCard
  //           name="My Repairs"
  //           fromModal={fromModal}
  //           route="/app/repair/my"
  //           icon={<Hardware style={{ color: '#8291AD' }} />}
  //         />
  //       </Grid>
  //     </Grid>
  //   )
  // }
  return (
    <div>
      <h2>{t('Empty')}</h2>
    </div>
  )
}

export default Items
