import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, FormControl, TextField, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

const InputButton = styled('input')({
  display: 'none',
})

const UploadBiTResults = () => {
  const [robot, setRobot] = useState('')
  const [filePath, setFilePath] = useState('')
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleRobotChange = value => {
    setRobot(value)
  }

  const onInputChange = event => {
    setFilePath(event.target.value)
  }

  const onCancel = () => {
    history.push(history.location.state.from, { from: 'bit' })
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("UploadBiTResults")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px' }}>
            <Grid container direction="column" justifyContent="space-evenly" sx={{ mt: '18px' }}>
              <div className="title-field">
                <Typography variant="subtitle1" component="div">
                  {t("Robot")}
                </Typography>
                <Box sx={{ minWidth: '360px' }}>
                  <FormControl fullWidth>
                    <TextField id="outlined" value={robot} onChange={e => handleRobotChange(e.target.value)} />
                  </FormControl>
                </Box>
              </div>

              <div className="title-field">
                <Typography variant="subtitle1" component="div">
                  {t("BiT Results")}
                </Typography>
                <TextField
                  id="standard-read-only-input"
                  value={filePath}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
                <label htmlFor="contained-button-file">
                  <InputButton
                    accept="text/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onInputChange}
                  />
                  <Button variant="contained" component="span" color="greyGreen">
                    {t("Browse")}
                  </Button>
                </label>
              </div>
            </Grid>
            <div className="button-wrapper">
              <Button variant="contained" color="greyGreen" sx={{ m: '0px 6px' }} onClick={onCancel}>
                {t("Cancel")}
              </Button>
              <Button variant="contained" color="alertPurple" sx={{ m: '0px 6px' }}>
                {t("Fail")}
              </Button>
              <Button variant="contained" sx={{ m: '0px 6px' }}>
                {t("Pass")}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}

export default UploadBiTResults
