import React from 'react'
import { useSnackbar } from 'notistack'
import { Grid, Typography, Box, Button } from '@mui/material'
import { Album, LocationOn, PersonAdd, RateReview, Report, Construction, Cloud } from '@mui/icons-material'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'

import ItemCard from './Item Card/ItemCard'
import CustomModal from './Modal/CustomModal'
import Store from '../../Store/Store'
import client from '../../Config/axiosClient'
import { useTranslation } from 'react-i18next'

const AppHome = observer(() => {
  const { authState } = useOktaAuth()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const idToken = authState.idToken.idToken
  const history = useHistory()
  const { t, i18n } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleClick = () => {
    const resetDatabase = async () => {
      await client.get(`/robot/reset`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
    }
    if (idToken) {
      resetDatabase()
    }
  }

  const action = key => {
    return (
      <Button variant="white" onClick={() => handleRoute(key)}>
        {t("Show")}
      </Button>
    )
  }

  const handleRoute = key => {
    history.push(window.location.pathname + '/snackbar')
    closeSnackbar(key)
  }
  const openSnackbar = () => {
    const message = t("SomeError")
    // Same types of warning as for the MUI snackbar
    i18n.changeLanguage("en")
    enqueueSnackbar(message, { variant: 'warning', action })
    // If we want to persist some error:
    // enqueueSnackbar(message, { variant: 'warning', action, persist: true })
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('ReliabilityGrowthTesting')}
      </Typography>
      <Button variant="contained" onClick={handleClick} style={{ display: 'none' }}>
        Reset robot database
      </Button>
      <Button variant="contained" onClick={openSnackbar}>
        {t("Snackbar")}
      </Button>
      <Grid
        container
        columnSpacing={3}
        rowSpacing={3}
        direction="row"
        sx={{ mt: 8 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <ItemCard name={t("AssetTracking")} icon={<LocationOn style={{ color: '#87A28E' }} />} />
        </Grid>
        <Grid item>
          <ItemCard name={t("Provisioning")} provisioningItem={true} icon={<Cloud style={{ color: '#6A768C' }} />} />
        </Grid>
        <Grid item>
          <ItemCard name={t("Onboarding")} icon={<PersonAdd style={{ color: '#EBC172' }} />} />
        </Grid>
        <Grid item>
          <ItemCard name={t("Testing")} testingItem={true} icon={<Album style={{ color: '#518A70' }} />} />
        </Grid>
        <Grid item>
          <ItemCard name={t("Repairs")} repairsItem={true} icon={<Construction style={{ color: '#8291AD' }} />} />
        </Grid>
        <Grid item style={{ display: 'none' }}>
          <ItemCard name={t("Evaluation")} icon={<RateReview style={{ color: '#AE72A3' }} />} />
        </Grid>
        <Grid item style={{ display: 'none' }}>
          <ItemCard name={t("Reporting")} icon={<Report style={{ color: '#6A768C' }} />} />
        </Grid>
        <CustomModal title={Store.modalTitle} />
      </Grid>
    </Box>
  )
})

export default AppHome
