import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Container,
  Paper,
  Tabs,
  Tab,
  // Grid,
  // FormControl,
  // TextField,
  // Button,
} from '@mui/material'
import TabsPanel from './TabsPanel'
import UserDetails from './UserDetails'
import ActivityHistory from './Tables/ActivityHistory'
import { useTranslation } from 'react-i18next'

const Profile = () => {
  // Tabs: User details, Activity History
  const [tabValue, setTabValue] = useState(0)
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  function allyProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("UserProfile")}
      </Typography>
      <Container maxWidth="false" sx={{ mt: 4 }}>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'inline-block',
            width: '32%',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="basic tabs example"
          >
            <Tab sx={{ width: '50%' }} label={t("Userdetails")} {...allyProps(0)} />
            <Tab sx={{ width: '50%' }} label={t("ActivityHistory")} {...allyProps(1)} />
          </Tabs>
        </Box>
        <Paper>
          <TabsPanel value={tabValue} index={0}>
            <UserDetails />
          </TabsPanel>
          <TabsPanel value={tabValue} index={1}>
            <ActivityHistory />
          </TabsPanel>
        </Paper>
      </Container>
    </Box>
  )
}

export default Profile
