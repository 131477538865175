import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, FormControl, TextField } from '@mui/material'
import { observer } from 'mobx-react'
import Store from '../../../Store/Store'
import './AddNewBattery.css'
import { useTranslation } from 'react-i18next'

const AddNewBattery = observer(() => {
  const [robot, setRobot] = useState('')
  const [oldBattery, setOldBattery] = useState('')
  const [newBattery, setNewBattery] = useState('')
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleRobotChange = value => {
    setRobot(value)
  }

  const handleOldBatteryChange = value => {
    setOldBattery(value)
  }

  const handleNewBatteryChange = value => {
    setNewBattery(value)
  }

  const onContinueTest = () => {
    Store.setOpenSnackbar(true)
    Store.setSeverity('success')
    Store.setMessage('New battery added')
    history.push('/app/testing/workstation')
  }

  const onCancel = () => {
    history.push(history.location.state.from, { from: 'new_battery' })
  }
  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("Start Test")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px' }}>
            <Typography variant="modal1" align="center" gutterBottom>
              {t("InstallNewBattery")}
            </Typography>
            <Typography variant="modal1" align="center">
              {t("ScanRobotAndBattery")}
            </Typography>
            <Grid container direction="column" justifyContent="space-evenly" sx={{ mt: '18px' }}>
              <div className="battery-row">
                <Typography variant="subtitle1" component="div">
                  {t("Robot")}
                </Typography>
                <Box sx={{ minWidth: '360px' }}>
                  <FormControl fullWidth>
                    <TextField id="outlined" value={robot} onChange={e => handleRobotChange(e.target.value)} />
                  </FormControl>
                </Box>
              </div>
              <div className="battery-row">
                <Typography variant="subtitle1" component="div">
                  {t("OldBattery")}
                </Typography>
                <Box sx={{ minWidth: '360px' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined"
                      value={oldBattery}
                      onChange={e => handleOldBatteryChange(e.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="battery-row">
                <Typography variant="subtitle1" component="div">
                  {t("NewBattery")}
                </Typography>
                <Box sx={{ minWidth: '360px' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined"
                      value={newBattery}
                      onChange={e => handleNewBatteryChange(e.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>
            </Grid>
            <div className="button-wrapper">
              <Button variant="contained" color="greyGreen" sx={{ m: '0px 6px' }} onClick={onCancel}>
                {t("Cancel")}
              </Button>
              <Button variant="contained" sx={{ m: '0px 6px' }} onClick={onContinueTest}>
                {t("ContinueTesting")}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
})

export default AddNewBattery
