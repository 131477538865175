import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

class ReportDataStore {
  // observables
  reportData = [
    {
      title: '',
      description: '',
    },
  ]

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, { name: 'ReportDataStore', properties: ['reportData'], storage: window.localStorage })
  }

  // action
  setReportData = reportData => {
    this.reportData = reportData
  }

  resetReportData = () => {
    this.setReportData([])
  }

  // actions change observables!
}

export default ReportDataStore
