import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material'
import { ArrowForward, AddComment, Message, Edit } from '@mui/icons-material'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { makeStyles } from '@mui/styles'
import ComponentDropdown from './Custom/ComponentDropdown'
import SubcomponentDropdown from './Custom/SubcomponentDropdown'
import SupplierDropdown from './Custom/SupplierDropdown'
import CustomEdit from './Custom/CustomEdit'
import Store from '../../../../Store/Store'
import DeletingComponentModal from '../../Modal/DeletingComponentModal'
import AddCommentModal from '../../Modal/AddCommentModal'
import { useTranslation } from 'react-i18next'

import './ComponentsStep.css'

const useStyles = makeStyles({
  headtable: {
    '& .MuiTableCell-head': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 20%)',
    },
  },
})

const ComponentStep = observer(({ handleContinue, fromTests }) => {
  const [component, setComponent] = useState('')
  const [subcomponent, setSubcomponent] = useState('')
  const [supplier, setSupplier] = useState('')
  const [serial, setSerial] = useState('')
  const [rows, setRows] = useState(Store.onboardingStore.onboardedData ? Store.onboardingStore.onboardedData : [])
  const [editRow, setEditRow] = useState()
  const [error, setError] = useState({
    componentError: false,
    subcomponentError: false,
    supplierError: false,
    serialError: false,
  })
  const [canAdd, setCanAdd] = useState(false)
  const [disableDone, setDisableDone] = useState(false)
  const [editing, setEditing] = useState(false)
  const [disableComponents, setDisableComponents] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openCommentsModal, setOpenCommentsModal] = useState(false)
  const [commentRow, setCommentRow] = useState([])
  const [deleteIndex, setDeleteIndex] = useState(0)
  const classes = useStyles()
  const subcomponentsData = Store.onboardingStore.subcomponentsData
  const componentsData = Store.onboardingStore.componentsData
  const suppliersData = Store.onboardingStore.suppliersData
  const { t } = useTranslation()


  useEffect(() => {
    if (fromTests) {
      return
    } else {
      if (rows.length !== 0 && !editing) {
        handleContinue(false)
      } else {
        handleContinue(true)
      }
    }
  }, [handleContinue, rows.length, fromTests, editing])

  useEffect(() => {
    if (!component || !subcomponent) {
      setCanAdd(false)
    } else if (!error.componentError && !error.subcomponentError) {
      setCanAdd(true)
    } else {
      setCanAdd(false)
    }
  }, [
    error.componentError,
    error.subcomponentError,
    error.supplierError,
    error.serialError,
    component,
    subcomponent,
    supplier,
    serial,
  ])

  const columns = [
    { id: 'component', label: 'Component', minWidth: 170 },
    { id: 'subcomponent', label: 'Subcomponent', minWidth: 170 },
    {
      id: 'supplier',
      label: 'Supplier',
      minWidth: 170,
    },
    {
      id: 'serial',
      label: 'Build/Lot/Serial Number',
      minWidth: 170,
    },
  ]

  const handleComponentChange = event => {
    setComponent(event.target.textContent)
    setSubcomponent('')
    Store.onboardingStore.setComponent(event.target.textContent)
  }

  const handleSubcomponentChange = event => {
    setSubcomponent(event.target.textContent)
  }

  const handleSupplierChange = e => {
    setSupplier(e.target.textContent)
  }

  const handleSerialChange = event => {
    setSerial(event.target.value)
    // handleError(event, 'serial')
  }

  const handleError = (event, from) => {
    if (from === 'component-select' && !component) {
      const errorType = 'componentError'
      checkValidation(event, errorType)
    } else if (from === 'subcomponent-select') {
      const errorType = 'subcomponentError'
      checkValidation(event, errorType)
    }
    // } else if (from === 'supplier-select' && !supplier) {
    //   const errorType = 'supplierError'
    //   checkValidation(event, errorType)
    // } else if (from === 'serial') {
    //   const errorType = 'serialError'
    //   checkValidation(event, errorType)
    // }
  }

  const checkValidation = (event, errorType) => {
    if (event.target.value === '') {
      setError({ ...error, [errorType]: true })
    } else {
      setError({ ...error, [errorType]: false })
    }
  }

  const startEditing = index => {
    setEditRow(index)
    setDisableComponents(true)
    setEditing(true)
  }

  const stopEditing = () => {
    setEditRow(-1)
    Store.onboardingStore.setComponent(component || '')
    setDisableComponents(false)
    setEditing(false)
  }

  const handleChange = (e, id, index) => {
    let valueChange = ''
    const { textContent } = e.target
    const { value } = e.target

    if (textContent === '') {
      valueChange = value
    } else {
      valueChange = textContent
    }
    let newRows = rows.map((row, i) => (i === index ? { ...row, [id]: valueChange } : row))
    newRows[index].supplier_id = suppliersData.find(val => val?.DisplayName === newRows[index].supplier)?.PK_Supplier
    if (newRows[index].subcomponent && newRows[index].subcomponent !== '') {
      newRows[index].component_id = subcomponentsData[newRows[index].component].find(
        val => val?.Level1 === newRows[index].subcomponent
      )?.PK_ComponentMapping
    } else {
      newRows[index].component_id = componentsData.find(
        val => val?.Component === newRows[index].component
      )?.PK_ComponentMapping
    }

    setRows(newRows)
    Store.onboardingStore.setOnboardedData(newRows)
  }

  const handleDisableDone = value => {
    setDisableDone(value)
  }

  const handleRemove = index => {
    let newRows = rows.filter((row, i) => i !== index)
    setRows(newRows)
    Store.onboardingStore.setOnboardedData(newRows)
    handleClose()
  }

  const handleAddRow = () => {
    let lastItemId = 0
    if (rows.length > 0) {
      lastItemId = rows[rows.length - 1].id
    } else {
      lastItemId = 0
    }
    let component_id = null
    if (subcomponent && subcomponent !== '') {
      component_id = subcomponentsData[component].find(val => val?.Level1 === subcomponent)?.PK_ComponentMapping
    } else {
      component_id = componentsData.find(val => val?.Component === component)?.PK_ComponentMapping
    }
    const newData = {
      id: lastItemId + 1,
      component: component,
      subcomponent: subcomponent,
      supplier: supplier,
      serial: serial,
      component_id: component_id,
      supplier_id: suppliersData.find(val => val?.DisplayName === supplier)?.PK_Supplier,
      comment: '',
    }
    let newRows = [...rows, newData]
    setRows(newRows)
    Store.onboardingStore.setOnboardedData(newRows)
    setComponent('')
    setSubcomponent('')
    setSupplier('')
    setSerial('')
    Store.onboardingStore.setComponent('')
  }

  const handleOpenModal = index => {
    setDeleteIndex(index)
    setOpenModal(true)
  }
  const handleOpenComments = row => {
    setCommentRow(row)
    setOpenCommentsModal(true)
  }

  const handleClose = () => {
    setOpenCommentsModal(false)
    setOpenModal(false)
  }

  const getRowsIndex = id => {
    return rows.findIndex(item => item.id === id)
  }

  const handleAddComment = action((row, comment) => {
    let tempRows = rows
    let index = getRowsIndex(row.id)
    tempRows[index] = { ...rows[index], comment }

    // console.log(tempRows)
    // console.log(tempRows[row.id - 1])

    setRows(tempRows)
    Store.onboardingStore.setOnboardedData(tempRows)
    handleClose()
  })

  return (
    <div style={{ padding: '10px' }}>
      <Paper sx={{ mb: '26px', p: '15px' }} key={rows.length}>
        <div className="add-row">
          <div className="add-row-column">
            <Typography variant="subtitle2">{t("Component")}</Typography>
            <ComponentDropdown
              value={component}
              handleChange={e => handleComponentChange(e)}
              handleError={(event, from) => handleError(event, from)}
              disable={disableComponents}
              error={error.componentError}
            />
          </div>
          <div className="add-row-column">
            <ArrowForward fontSize="small" />
          </div>
          <div className="add-row-column">
            <Typography variant="subtitle2">{t("Subcomponent")}</Typography>
            <SubcomponentDropdown
              value={subcomponent || ''}
              handleChange={e => handleSubcomponentChange(e)}
              handleError={(event, from) => handleError(event, from)}
              disable={disableComponents}
              error={error.subcomponentError}
            />
          </div>
          <div className="add-row-column">
            <ArrowForward fontSize="small" />
          </div>
          <div className="add-row-column">
            <Typography variant="subtitle2">{t("Supplier")}</Typography>
            <SupplierDropdown
              value={supplier}
              handleChange={e => handleSupplierChange(e)}
              // handleError={(event, from) => handleError(event, from)}
              // error={error.supplierError}
              disable={disableComponents}
            />
          </div>
          <div className="add-row-column">
            <ArrowForward fontSize="small" />
          </div>
          <div className="add-row-column">
            <Typography variant="subtitle2">{t("BuildSerialLot")}</Typography>
            <TextField
              value={serial}
              // error={error.serialError}
              size="small"
              disabled={disableComponents}
              onChange={e => handleSerialChange(e)}
              sx={{ width: '173px' }}
              // onBlur={event => handleError(event, 'serial')}
            />
          </div>
          <div className="add-button">
            <Button
              variant="contained"
              color="greyGreen"
              sx={{ width: '84px' }}
              onClick={handleAddRow}
              disabled={!canAdd}
            >
              {t("Add")}
            </Button>
          </div>
        </div>
      </Paper>
      <Paper>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.headtable}>
                {columns.map(column => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell style={{ maxWidth: 200 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                let boldText = ''
                const currentlyEditing = editRow === index
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} sx={{ fontWeight: `${boldText}` }}>
                          {currentlyEditing ? (
                            <CustomEdit
                              id={column.id}
                              value={value}
                              index={index}
                              handleChange={handleChange}
                              currentlyEditing={currentlyEditing}
                              handleDisable={handleDisableDone}
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      )
                    })}
                    <TableCell sx={{ width: 270 }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {row.comment && (
                          <>
                            <Tooltip title={row.comment}>
                              <IconButton sx={{ mr: '5px' }} color="navbarGreen">
                                <Message />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit comment">
                              <IconButton
                                sx={{ mr: '5px' }}
                                color="switchBlue"
                                onClickCapture={() => handleOpenComments(row, index)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {row.comment === '' && (
                          <>
                            <Tooltip title="No comment">
                              <span>
                                <IconButton sx={{ mr: '5px' }} color="switchBlue" disabled>
                                  <Message />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="Add comment">
                              <IconButton
                                sx={{ mr: '5px' }}
                                color="switchBlue"
                                onClickCapture={() => handleOpenComments(row, index)}
                              >
                                <AddComment />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}

                        <div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}>
                          {currentlyEditing ? (
                            <Button
                              variant="contained"
                              color="greyGreen"
                              sx={{ mr: '12px', width: '84px' }}
                              onClick={() => stopEditing()}
                              disabled={disableDone}
                            >
                              {t("Done")}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="greyGreen"
                              sx={{ mr: '12px', width: '84px' }}
                              onClick={() => startEditing(index)}
                            >
                              {t("Edit")}
                            </Button>
                          )}
                          {/* Old delete without notification about deletion of a row */}
                          {/* <Button
                          variant="contained"
                          color="alertPurple"
                          sx={{ width: '84px' }}
                          onClick={() => handleRemove(index)}
                          disabled={disableComponents}
                          >
                          Delete
                        </Button> */}
                          <Button
                            variant="contained"
                            color="alertPurple"
                            sx={{ width: '84px' }}
                            onClickCapture={() => handleOpenModal(index)}
                            disabled={disableComponents}
                          >
                            {t("Delete")}
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DeletingComponentModal
        open={openModal}
        handleRemove={handleRemove}
        handleClose={handleClose}
        index={deleteIndex}
      />
      <AddCommentModal
        open={openCommentsModal}
        handleClose={handleClose}
        handleAddComment={handleAddComment}
        selectedRow={commentRow}
      />
    </div>
  )
})

export default ComponentStep
