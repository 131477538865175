import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  Button,
  // FormControl,
  // FormControlLabel,
  // FormGroup,
  // Checkbox,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import './InspectRUT.css'

const InspectRUT = () => {
  // const [filters, setFilters] = useState({
  //   'Inspect item 1': false,
  //   'Verify this and that': false,
  //   'Confirm something else': false,
  // })
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // const onFilterChanged = name => event => {
  //   setFilters({ ...filters, [name]: event.target.checked })
  // }

  const handlePass = () => {
    history.push('/app/testing/start_test', { from: '/app/testing/workstation' })
  }

  const handleCancel = () => {
    history.push(history.location.state.from)
  }

  const handleFail = () => {
    history.push('/app/testing/error', { from: '/app/testing/workstation', fail: true })
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("InspectRUT")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px' }}>
            <Typography variant="modal1" align="center" gutterBottom>
              {t("InspectRUTpriotTesting")}
            </Typography>
            <div className="rut-list">
              <div className="rut-list-row">
                <Typography variant="modal1" sx={{ width: '40px' }}>
                  1.
                </Typography>
                <Typography variant="modal2">Inspect item 1</Typography>
              </div>
              <div className="rut-list-row">
                <Typography variant="modal1" sx={{ width: '40px' }}>
                  2.
                </Typography>
                <Typography variant="modal2">Verify this and that</Typography>
              </div>
              <div className="rut-list-row">
                <Typography variant="modal1" sx={{ width: '40px' }}>
                  3.
                </Typography>
                <Typography variant="modal2">Confirm something else</Typography>
              </div>
            </div>
            <Typography sx={{ mt: '20px' }}>
              {t("InspectNote")}
            </Typography>
            {/* // Uncomment for checkbox list
            <div className="rut-checkbox-list">
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={filters['Inspect item 1']}
                        onChange={onFilterChanged('Inspect item 1')}
                        value="Inspect item 1"
                        sx={{ pr: '16px', ml: '26.5px' }}
                      />
                    }
                    label="Inspect item 1"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={filters['Ready']}
                        onChange={onFilterChanged('Verify this and that')}
                        value="Verify this and that"
                        sx={{ pr: '16px', ml: '26.5px' }}
                      />
                    }
                    label="Verify this and that"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={filters['Confirm something else']}
                        onChange={onFilterChanged('Confirm something else')}
                        value="Confirm something else"
                        sx={{ pr: '16px', ml: '26.5px' }}
                      />
                    }
                    label="Confirm something else"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div> */}
            <div className="button-wrapper">
              <Button variant="contained" color="greyGreen" sx={{ m: '0px 6px' }} onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Button variant="contained" color="alertPurple" sx={{ m: '0px 6px' }} onClick={handleFail}>
                {t("Fail")}
              </Button>
              <Button variant="contained" sx={{ m: '0px 6px' }} onClick={handlePass}>
                {t("Pass")}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}

export default InspectRUT
