import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Box, Container, Paper, Step, Stepper, StepLabel, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import RobotInfoStep from './RobotInfoStep'
import BiTStep from './BiTStep'
import ComponentStep from './ComponentsStep'
import SuccessStep from './SuccessStep'
import PrintLabelModal from '../../Modal/PrintLabelModal'
import RobotNotFound from '../../Modal/RobotNotFound'
import RUTOnboarded from '../../Modal/RUTOnboarded'
import BitFileError from '../../Modal/BitFileError'
import Store from '../../../../Store/Store'
import DataWillBeLostModal from '../../Modal/DataWillBeLostModal'
import client from '../../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'


const steps = ['Enter Robot Info', 'Upload BiT Results', 'Edit Components', 'Print Label']

const RobotOnboarding = observer(() => {
  const [activeStep, setActiveStep] = useState(Store.onboardingStore.activeStep)
  const [contentSize, setContentSize] = useState(Store.onboardingStore.contentSize)
  const [openModal, setOpenModal] = useState(false)
  const [openRobotNotFound, setOpenRobotNotFound] = useState(false)
  const [openRUTOnboarded, setOpenRUTOnboarded] = useState(false)
  const [openBitError, setOpenBitError] = useState(false)
  const [cantContinue, setCantContinue] = useState(true)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState(
    Store.onboardingStore.bitFileComments ? Store.onboardingStore.bitFileComments : ''
  )
  const [uploadFile, setUploadFile] = useState([])
  const [fileName, setFileName] = useState(Store.onboardingStore.bitFilePath ? Store.onboardingStore.bitFilePath : '')
  const [serial, setSerial] = useState(
    Store.onboardingStore.robotData.RobotSN ? Store.onboardingStore.robotData.RobotSN : ''
  )
  const [test, setTest] = useState(Store.onboardingStore.selectedTest ? Store.onboardingStore.selectedTestHelper : '')
  const [robot, setRobot] = useState(
    Store.onboardingStore.selectedRobot ? Store.onboardingStore.selectedRobotHelper : ''
  )
  const [isBlocked, setIsBlocked] = useState(Store.onboardingStore.isBlocked)
  const [status, setStatus] = useState(0)

  const { authState } = useOktaAuth()
  const history = useHistory()
  const idToken = authState.idToken.idToken
  const fkRobot = Store.onboardingStore.robotData['PK_Robot']
  const { t } = useTranslation()


  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleIsBlocked = value => {
    setIsBlocked(value)
    Store.onboardingStore.setIsBlocked(value)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    handleSize(activeStep + 1)
    setCantContinue(true)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    handleSize(activeStep - 1)
    setCantContinue(true)
  }

  const handleFinish = async () => {
    setLoading(true)
    // Remove unnecessary status from selectedTest (FOR NOW)
    let selectedTest = Store.onboardingStore.selectedTest
    delete selectedTest.status
    let body = {
      robot_id: fkRobot,
      onboard_data: Store.onboardingStore.onboardedData,
      test: selectedTest,
      jira_id: Store.onboardingStore.selectedRobot.id,
      jira_key: Store.onboardingStore.selectedRobot.key,
    }
    const res = await client
      .post(`/robot/onboard`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })

    if (res?.status === 200) {
      setLoading(false)
      handleIsBlocked(false)
      Store.onboardingStore.clearPersistedStore()
      history.push('/app')
    }
  }

  const handleSize = stepNumber => {
    if (stepNumber === 0 || stepNumber === 1 || stepNumber === 3) {
      setContentSize('sm')
      Store.onboardingStore.setContentSize('sm')
    }
    if (stepNumber === 2) {
      setContentSize('lg')
      Store.onboardingStore.setContentSize('lg')
    }
    Store.onboardingStore.setActiveStep(stepNumber)
  }

  const handleOpenModalPrint = () => setOpenModal(true)
  const handleClose = () => {
    setOpenModal(false)
    setOpenRobotNotFound(false)
    setOpenRUTOnboarded(false)
    setOpenBitError(false)
  }

  const getTest = test => {
    setTest(test)
  }

  const getRobot = robot => {
    setRobot(robot)
  }

  const getSerial = serial => {
    setSerial(serial)
  }

  const checkSerial = async () => {
    // console.log('Test', test, '-', Store.onboardingStore.selectedTestHelper)
    // console.log('Robot', robot, '-', Store.onboardingStore.selectedRobotHelper)
    // console.log('Serial', serial, '-', Store.onboardingStore.robotData.RobotSN)
    if (
      serial === Store.onboardingStore.robotData.RobotSN &&
      test === Store.onboardingStore.selectedTestHelper &&
      robot === Store.onboardingStore.selectedRobotHelper
    ) {
      handleNext()
    } else if (
      (!serial && Store.onboardingStore.robotData.RobotSN) ||
      (!test && Store.onboardingStore.selectedTestHelper) ||
      (!robot && Store.onboardingStore.selectedRobotHelper)
    ) {
      // After reloading the page, we dont have a serial, but we have the stored serial
      handleNext()
    } else {
      setLoading(true)
      const res = await client.post(
        `/robot/search`,
        {
          serial_number: serial,
          test_id: Store.onboardingStore.selectedTest.id,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      // console.log(res.data)
      checkStatus(res.data)
    }
  }

  const fillFormData = event => {
    setUploadFile(event.target.files[0])
    setFileName(event.target.files[0].name)
  }

  const fillComentsFormData = comments => {
    setComments(comments)
  }

  const handleUploadFile = async () => {
    // console.log('File name', fileName, '-', Store.onboardingStore.bitFilePath)
    // console.log('Comments', comments, '-', Store.onboardingStore.bitFileComments)
    if (fileName === Store.onboardingStore.bitFilePath && comments === Store.onboardingStore.bitFileComments) {
      handleNext()
    } else {
      setLoading(true)
      let formData = new FormData()
      formData.append('upload_file', uploadFile)
      formData.append('fk_robot', fkRobot)
      formData.append('filename', fileName)
      formData.append('comments', comments)
      // formData.append('fk_user', '4')

      const res = await client({
        method: 'post',
        url: `/bitfile`,
        data: formData,
        headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'multipart/form-data' },
      }).catch(error => {
        setStatus(error.request.status)
        setOpenBitError(true)
        setLoading(false)
      })

      if (res) {
        setLoading(false)
        handleNext()
        Store.onboardingStore.setBitFile(fileName)
        Store.onboardingStore.setComments(comments)
      }
    }
  }

  const checkStatus = data => {
    // If data is empty [] open modal
    // Serial number example: 1234
    if (data.length == 0) {
      setOpenRobotNotFound(true)
      setLoading(false)
    } else {
      // If already onboarded open modal
      // Serial number example: RS1
      if (data[0].Onboarded) {
        setOpenRUTOnboarded(true)
        setLoading(false)
      }

      // If serial number is ok and robot is not onboarded continue to next step
      // Serial number example: RS2
      if (!data[0].Onboarded) {
        Store.onboardingStore.setRobotInfoData(data[0])
        Store.onboardingStore.setTestHelper(test)
        Store.onboardingStore.setRobotHelper(robot)
        setLoading(false)
        handleNext()
      }
    }
  }

  const handleContinue = value => {
    setCantContinue(value)
  }
  const stepsLength = steps.length - 1
  const errorMessage = t("ExitOnboardingError")

  return (
    <div>
      <Container maxWidth="sm">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Container>
      <Container maxWidth={contentSize}>
        <Paper sx={{ mt: 4 }}>
          {activeStep === 0 && (
            <RobotInfoStep
              getTest={getTest}
              getRobot={getRobot}
              getSerial={getSerial}
              handleContinue={handleContinue}
              handleBlock={handleIsBlocked}
            />
          )}
          {activeStep === 1 && (
            <BiTStep
              handleContinue={handleContinue}
              fillFormData={fillFormData}
              fillComentsFormData={fillComentsFormData}
            />
          )}
          {activeStep === 2 && <ComponentStep handleContinue={handleContinue} />}
          {activeStep === 3 && <SuccessStep />}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {activeStep > 0 && (
              // {activeStep > 0 && activeStep !== stepsLength && (
              <Button variant="contained" color="greyGreen" sx={{ m: '25px 4px' }} onClick={handleBack}>
                {t("Back")}
              </Button>
            )}
            {activeStep === 0 && activeStep !== stepsLength && (
              <LoadingButton
                onClick={checkSerial}
                loading={loading}
                disabled={cantContinue}
                variant="contained"
                sx={{ m: '25px 4px' }}
              >
                {t("Next")}
              </LoadingButton>
            )}
            {activeStep === 1 && activeStep !== stepsLength && (
              <LoadingButton
                onClick={handleUploadFile}
                loading={loading}
                disabled={cantContinue}
                variant="contained"
                sx={{ m: '25px 4px' }}
              >
                {t("Next")}
              </LoadingButton>
            )}
            {activeStep > 1 && activeStep !== stepsLength && (
              <Button variant="contained" sx={{ m: '25px 4px' }} onClick={handleNext} disabled={cantContinue}>
                {t("Next")}
              </Button>
            )}
            {activeStep === stepsLength && (
              <Button variant="contained" color="alertPurple" sx={{ m: '25px 4px' }} onClick={handleOpenModalPrint}>
                {t("PrintLabel")}
              </Button>
            )}
            {activeStep === stepsLength && (
              <LoadingButton onClick={handleFinish} loading={loading} variant="contained" sx={{ m: '25px 4px' }}>
                {t("Finish")}
              </LoadingButton>
            )}
          </div>
          <PrintLabelModal open={openModal} handleClose={handleClose} />
          <RobotNotFound open={openRobotNotFound} handleClose={handleClose} />
          <RUTOnboarded open={openRUTOnboarded} handleClose={handleClose} />
          <BitFileError open={openBitError} handleClose={handleClose} status={status} />
        </Paper>
      </Container>
      <DataWillBeLostModal isBlocked={isBlocked} errorMessage={errorMessage} />
    </div>
  )
})

export default RobotOnboarding
