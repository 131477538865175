import React, { useState, useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { observer } from 'mobx-react'
import { Container, CssBaseline, Box, Typography, Button, CircularProgress } from '@mui/material'
import client from '../../Config/axiosClient'

import Store from '../../Store/Store'
import { useTranslation } from 'react-i18next'

const Home = observer(() => {
  const { authState, oktaAuth } = useOktaAuth()
  const history = useHistory()
  const [userInfo, setUserInfo] = useState(null)
  const [status, setStatus] = useState(false)
  const [idToken, setIdToken] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null)
      Store.accountInfo.setCurrentUser('')
      oktaAuth.tokenManager.clear()
    } else {
      setIdToken(authState.idToken.idToken)
      oktaAuth
        .getUser()
        .then(info => {
          let temp = info
          delete temp.headers
          setUserInfo(temp)
          Store.accountInfo.setCurrentUser(temp)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [authState, oktaAuth]) // Update if authState changes

  useEffect(() => {
    let isSubscribed = true
    const getAud = token => {
      let base64Url = token.split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      return JSON.parse(jsonPayload).aud
    }
    const sendUserInfo = async () => {
      const statusReq = await client.post(`/user/info`, {...userInfo, aud: getAud(idToken)}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      // console.log(statusReq)
      Store.accountInfo.setUserRole(statusReq.data.role)
      setStatus(true)
    }
    if (idToken && userInfo && isSubscribed) {
      sendUserInfo()
    }

    return () => (isSubscribed = false)
  }, [idToken, userInfo])

  const login = async () => {
    history.push('/login')
  }

  if (!authState) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {authState?.isAuthenticated && (!userInfo || !status) && (
          <Box sx={{ mt: 6 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </Box>
        )}
        {authState.isAuthenticated && userInfo && status && <Redirect to={{ pathname: '/app' }} />}
        {!authState.isAuthenticated && (
          <Box>
            <Typography variant="h4" align="center" style={{ color: '#6CB86A' }}>
              {t('ReliabilityGrowthTesting')}
            </Typography>
            <Box style={{ backgroundColor: 'white' }} boxShadow={5} sx={{ mt: 6 }}>
              <Box sx={{ marginTop: 8 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h5" sx={{ mt: 4, mb: 3 }}>
                    {t('Youarenotsignedin')}
                  </Typography>
                  <Button sx={{ mb: 4.5 }} variant="contained" onClick={login}>
                    {t('SignIn')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </>
  )
})

export default Home
