import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { Popover, MenuItem, IconButton, Divider, List, Collapse } from '@mui/material'
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks'
import './UserMenu.css'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'

const UserMenu = ({ children, logout }) => {
  const { authState } = useOktaAuth()
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const isLoggedIn = authState.isAuthenticated
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })

  const handleOpenSubMenu = () => {
    setOpen(!open)
  }

  const handleLangaugeChange = language_code => {
    i18n.changeLanguage(language_code)
  }

  const handleRouteChange = route => {
    popupState.close()
    history.push(`${route}`)
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        {...bindTrigger(popupState)}
      >
        {children}
      </IconButton>
      <Popover
        className="menu-position"
      {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {isLoggedIn && (
          <>
            <MenuItem onClick={() => handleRouteChange('/app/user')} sx={{ width: '210px' }}>
              {t('UserProfile')}
            </MenuItem>
            <MenuItem onClick={() => handleRouteChange('/app/settings')} sx={{ width: '210px' }}>
              {t('UserSettings')}
            </MenuItem>
            <ListItemButton onClick={handleOpenSubMenu}>
              <ListItemText primary={t('Language')} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('English')} onClick={() => handleLangaugeChange('en')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('Malay')} onClick={() => handleLangaugeChange('ms')} />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider className="divider" variant="middle" />
            <MenuItem className="logout" onClick={() => handleLogout()} sx={{ width: '210px', color: '' }}>
              {t('Log Out')}
            </MenuItem>
          </>
        )}
        {!isLoggedIn && (
          <>
            <MenuItem className="logout" onClick={() => handleRouteChange('/login')} sx={{ width: '210px', color: '' }}>
              {t('Log In')}
            </MenuItem>
            <Divider className="divider" variant="middle" />
            <ListItemButton onClick={handleOpenSubMenu}>
              <ListItemText primary={t('Language')} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('English')} onClick={() => handleLangaugeChange('en')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('Malay')} onClick={() => handleLangaugeChange('ms')} />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
      </Popover>
    </>
  )
}

export default UserMenu
