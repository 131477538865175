import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, FormControl, TextField, styled } from '@mui/material'
import './ErrorReport.css'
import { observer } from 'mobx-react'
import imageCompression from 'browser-image-compression'
import { LoadingButton } from '@mui/lab'
import Store from '../../../../Store/Store'
import client from '../../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'

const InputButton = styled('input')({
  display: 'none',
})

const ErrorReport = observer(() => {
  const [robot, setRobot] = useState('')
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const history = useHistory()
  const reportData = Store.reportData.reportData
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleRobotChange = value => {
    setRobot(value)
  }

  // title field change title depending on error type
  // extract error message from previous screen
  const onCancel = () => {
    if (history.location.state?.fail) {
      history.push(history.location.state?.from)
    } else {
      history.push(history.location.state.from, { from: 'error' })
    }
  }

  const handleSaveError = async () => {
    setLoading(true)
    let formData = new FormData()
    formData.append('object_name', 'robot')
    formData.append('object_id', '2')
    formData.append('name', 'General Robot Error')
    formData.append('description', description)
    formData.append('rut_id', '2')

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    for (let file of files) {
      const compressedFile = await imageCompression(file, options)
      formData.append('images', compressedFile, file.name)
      formData.append('image_names', file.name)
    }

    await client({
      method: 'post',
      url: `/error`,
      data: formData,
      headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'multipart/form-data' },
    }).catch(error => {
      console.error(error)
      setLoading(false)
    })
    setLoading(false)
  }

  const onInputChange = async event => {
    if (event.target.value !== '' && event.target.files) {
      setFiles(event.target.files)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("Error Report")}
      </Typography>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px' }}>
            <Grid container direction="column" justifyContent="space-evenly" sx={{ mt: '18px' }}>
              <div className="report-columns">
                <Typography variant="subtitle1" component="div">
                  {reportData.title}
                </Typography>
                <Box sx={{ minWidth: '75%' }}>
                  <FormControl fullWidth>
                    <TextField id="outlined" value={robot} onChange={e => handleRobotChange(e.target.value)} />
                  </FormControl>
                </Box>
              </div>
              <div className="report-columns">
                <Typography variant="subtitle1" component="div">
                  {t("Error")}
                </Typography>
                <Typography variant="subtitle1" sx={{ minWidth: '75%' }}>
                  {reportData.description}
                </Typography>
              </div>
              <div className="report-columns">
                <Typography variant="subtitle1" component="div" sx={{ pb: '75px' }}>
                  {t("Failuredescription")}
                </Typography>
                <Box sx={{ minWidth: '75%' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={5}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="report-columns">
                <Typography variant="subtitle1" component="div">
                  {t("Photos")}
                </Typography>
                <label htmlFor="contained-button-file" style={{ minWidth: '75%' }}>
                  <InputButton
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onInput={onInputChange}
                  />
                  <Button variant="contained" component="span" color="greyGreen">
                    {t("Browse")}
                  </Button>
                </label>
              </div>
            </Grid>
            <div className="button-wrapper">
              <Button variant="contained" color="greyGreen" sx={{ m: '0px 6px' }} onClick={onCancel}>
                {t("Cancel")}
              </Button>
              <LoadingButton variant="contained" sx={{ m: '0px 6px' }} onClick={handleSaveError} loading={loading}>
                {t("Save")}
              </LoadingButton>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
})

export default ErrorReport
