import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Toolbar,
  Link,
  Divider,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import './RobotDetail.css'
import { RobotDetailData } from '../../../../Store/MOCK_DATA'
import { RobotComponents } from '../../../../Store/MOCK_DATA'
import { RobotBitResults } from '../../../../Store/MOCK_DATA'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  headtable: {
    '& .MuiTableCell-head': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 20%)',
    },
  },
})

const RobotDetail = () => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()

  const dateFormater = cell => {
    // const date = new Date(cell);
    // console.log(JSON.stringify(date));
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleDateString()

    return formatedDate
  }

  const CustomToolbar = tableName => {
    const name = tableName.tableName

    return (
      <Toolbar sx={{ backgroundColor: '#6A768C', color: '#FFFFFFDE' }}>
        <Typography variant="h6" id="tableTitle" component="div">
          {name}
        </Typography>
      </Toolbar>
    )
  }

  // MOCK DATA
  let info = RobotDetailData[0]
  let rows = RobotBitResults
  let rowsComponents = RobotComponents

  const columns = [
    {
      id: 'date',
      label: 'Date',
      minWidth: 170,
    },
    { id: 'status', label: t("Status"), minWidth: 170 },
  ]

  const columnsComponents = [
    {
      id: 'module',
      label: t("Module"),
      minWidth: 170,
    },
    {
      id: 'component',
      label: t("Component"),
      minWidth: 170,
    },
    {
      id: 'supplier',
      label: t("Supplier"),
      minWidth: 170,
    },
    {
      id: 'serial',
      label: t("BuildSerialLot"),
      minWidth: 170,
    },
  ]

  const goRepair = robotName => {
    history.push('/app/repair_robot/' + robotName)
  }

  return (
    <Box sx={{ mb: '35px' }}>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {info.name}
      </Typography>

      <Paper sx={{ p: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: '8px', maxWidth: '100%', height: '50px' }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center" sx={{ mt: '6.5px' }}>
            <Grid item>
              <Button variant="contained">{t("SwapBattery")}y</Button>
            </Grid>
            <Grid item>
              <Button variant="contained">{t("BitTest")}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained">{t("ReportError")}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => goRepair(info.name)}>{t("Repair")}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="greyGreen">
                {t("AddonTests")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="alertPurple">
                {t("Retire")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="alertPurple">
                {t("Removefromtestbed")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mb: '28px',
              maxWidth: '100%',
              height: '120px',
              pl: '38px',
              pr: '10px',
            }}
          >
            <Grid container direction="column" justifyContent="space-around" alignItems="flex-start">
              <div>
                <Grid item sx={{ mr: '12px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t("Serialnumber")}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="detail4">{info.serial}</Typography>
                </Grid>
              </div>
              <div>
                <Grid item sx={{ mr: '12px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t("BLID")}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="detail4">{info.blid}</Typography>
                </Grid>
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid container direction="column" justifyContent="space-around" alignItems="center">
              <div className="column-container">
                <div className="row-container">
                  <Grid item sx={{ width: '52px', mr: '12px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {t("Status")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" color="#6CB86A">
                      {info.status}
                    </Typography>
                  </Grid>
                </div>
                <div className="row-container">
                  <Grid item sx={{ width: '52px', mr: '12px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {t("Battery")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{info.battery}</Typography>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid container direction="column" justifyContent="space-around" alignItems="center">
              <div className="column-container">
                <div className="row-container">
                  <Grid item sx={{ width: '69px', mr: '12px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {t("Missions")}:
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mr: '12px' }}>
                    <Typography variant="subtitle2">{info.missions}</Typography>
                  </Grid>
                  <Grid item>
                    <Link variant="body2" color="#6CB86A" sx={{ cursor: 'pointer' }}>
                      {t("Missions")}
                    </Link>
                  </Grid>
                </div>
                <div className="row-container">
                  <Grid item sx={{ width: '69px', mr: '12px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {t("Errors")}:
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mr: '12px' }}>
                    <Typography variant="subtitle2">{info.errors}</Typography>
                  </Grid>
                  <Grid item>
                    <Link variant="body2" color="#6CB86A" sx={{ cursor: 'pointer' }}>
                      {t("History")}
                    </Link>
                  </Grid>
                </div>
                <div className="row-container">
                  <Grid item sx={{ width: '69px', mr: '12px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {t("Runtime")}:
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mr: '12px' }}>
                    <Typography variant="subtitle2">{info.runtime}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{t("hours")}</Typography>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Box>
          {/* Components table */}
          <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3, mb: '35px' }}>
            <CustomToolbar tableName="Components" />
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.headtable}>
                    {columnsComponents.map(column => (
                      <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsComponents.map(row => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.serial}>
                        {columnsComponents.map(column => {
                          const componentValue = row[column.id]
                          return <TableCell key={column.id}>{componentValue}</TableCell>
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container direction="row" alignItems="center" sx={{ mt: '10px', mb: '10px', ml: '20px' }}>
              <Grid item sx={{ mr: '20px' }}>
                <Button variant="contained">{t("Editcomponents")}</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="greyGreen">
                  {t("AddonTests")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {/* BiT Results table */}
          <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3, mb: '35px' }}>
            <CustomToolbar tableName={t("BiTResults")} />
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.headtable}>
                    {columns.map(column => (
                      <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.date}>
                        {columns.map(column => {
                          let value = row[column.id]
                          if (column.id === 'date') {
                            value = dateFormater(value)
                          }
                          return <TableCell key={column.id}>{value}</TableCell>
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Paper>
    </Box>
  )
}

export default RobotDetail
