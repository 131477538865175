import { makeAutoObservable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

class AccountInfoStore {
  // observables
  isLoggedIn
  currentUser = ''
  userRole = ''

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'AccountInfoStore',
      properties: ['currentUser', 'userRole'],
      storage: window.localStorage,
    })
  }

  // action
  setCurrentUser = currentUser => {
    this.currentUser = currentUser
  }

  setUserRole = role => {
    this.userRole = role
  }

  clearCurrentUser = () => {
    this.currentUser = ''
    this.userRole = ''
    clearPersistedStore(this)
  }

  // actions change observables!
}

// This is exporting a singleton, one instance of a Store
export default AccountInfoStore
