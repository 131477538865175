import React, { useState } from 'react'
import { Backdrop, Modal, Fade, Button, Typography } from '@mui/material'
import { BatteryCharging20, Error } from '@mui/icons-material'
import { Close } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import Store from '../../../Store/Store'
import { ErrorData } from '../../../Store/MOCK_DATA'
import './WorkstationTestbedDetail.css'
import RequestRepairModal from '../Modal/RequestRepairModal'
import SimpleCheckModal from '../Modal/SimpleCheckModal'
import { useTranslation } from 'react-i18next'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '1000px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const WorkstationTestbedDetail = observer(({ open, handleClose }) => {
  const [requestedCleaning, setRequestedCleaning] = useState(Store.testingDataStore.requestedCleaning)
  const [requestedRepair, setRequestedRepair] = useState(Store.testingDataStore.requestedRepair)
  const [openRequestRepairModal, setOpenRequestRepairModal] = useState(false)
  const [openCheckModal, setOpenCheckModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalType, setModalType] = useState('')
  const history = useHistory()
  const classes = useStyles()
  const errorData = ErrorData
  const { t } = useTranslation()

  const robotName = 'Magnus-EP2-15'

  // Make battery notification and error notification a seperate component
  const batteryInfo = 'Battery SOC is <15%'

  const handleNewBattery = () => {
    history.push('/app/testing/new_battery', { from: '/app/testing/workstation' })
  }

  const handleBiT = () => {
    history.push('/app/testing/upload_bit', { from: '/app/testing/workstation' })
  }

  const onReportError = () => {
    // Depending on the type of error saved in store, Error description and Error type (Robot/Battery/Testbed) of Error Report change
    const errorType = 'error2'
    // const errorType = 'testbed'
    // const errorType = 'battery'

    let row = []
    errorData.map(error => {
      if (error.type === errorType) {
        row = {
          title: `${error.title}`,
          description: `${error.description}`,
        }
        Store.reportData.setReportData(row)
      }
    })

    history.push('/app/testing/error', { from: '/app/testing/workstation' })
  }

  const handleRequestCleaning = () => {
    Store.testingDataStore.setRequestedCleaning(true)
    setRequestedCleaning(true)
    handleCloseModals()
  }

  const handleCompleteCleaning = () => {
    Store.testingDataStore.setRequestedCleaning(false)
    setRequestedCleaning(false)
    handleCloseModals()
  }

  const handleRequestRepair = () => {
    Store.testingDataStore.setRequestedRepair(true)
    setRequestedRepair(true)
    handleCloseModals()
  }

  const handleCompleteRepair = () => {
    Store.testingDataStore.setRequestedRepair(false)
    Store.testingDataStore.setRepairComment('')
    setRequestedRepair(false)
    handleCloseModals()
  }

  const handleOpenRepairModal = () => {
    setOpenRequestRepairModal(true)
  }

  const handleOpenCheckModal = type => {
    setModalType(type)
    if (type === 'repair-complete') {
      setModalTitle(t("CompleteRepair?"))
    }
    if (type === 'cleaning-request') {
      setModalTitle(t("RequestCleaning?"))
    }
    if (type === 'cleaning-complete') {
      setModalTitle(t("CompleteCleaning?"))
    }
    setOpenCheckModal(true)
  }

  const handleCloseModals = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenRequestRepairModal(false)
      setOpenCheckModal(false)
    }
  }

  const handleCheck = type => {
    if (type === 'repair-complete') {
      handleCompleteRepair()
    }
    if (type === 'cleaning-request') {
      handleRequestCleaning()
    }
    if (type === 'cleaning-complete') {
      handleCompleteCleaning()
    }
  }

  const onRepairRobot = robotName => {
    history.push('/app/repair_robot/' + robotName)
  }

  return (
    <div>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px', marginRight: '8px' }}>
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <div className="top-detail">
              <div className="detail-padding">
                <div style={{ display: 'flex' }}>
                  <div className="detail-item">
                    <Typography variant="modal3">1.C5</Typography>
                    <Typography>{t("Location")}</Typography>
                  </div>

                  <div className="detail-item">
                    <Typography variant="modal3">DRY-032</Typography>
                    <Typography>{t("TestBed")}</Typography>
                  </div>
                  <div className="detail-item">
                    <span style={{ pointerEvents: 'none' }}>
                      <Button
                        variant="contained"
                        color="lightOrange"
                        disableElevation
                        sx={{ width: '92px', height: '36px' }}
                      >
                        {t("Occupied")}
                      </Button>
                    </span>
                  </div>
                </div>
                <div className="buttons-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      sx={{ mr: '6px' }}
                      disabled={requestedCleaning}
                      onClick={() => handleOpenCheckModal('cleaning-request')}
                    >
                      {t("RequestCleaning")}
                    </Button>
                    <Button
                      variant="contained"
                      color="greyGreen"
                      sx={{ m: '0px 6px' }}
                      disabled={!requestedCleaning}
                      onClick={() => handleOpenCheckModal('cleaning-complete')}
                    >
                      {t("CompleteCleaning")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      sx={{ m: '0px 6px' }}
                      disabled={requestedRepair}
                      onClick={handleOpenRepairModal}
                    >
                      {t("RequestRepair")}
                    </Button>
                    <Button
                      variant="contained"
                      color="greyGreen"
                      sx={{ m: '0px 6px' }}
                      disabled={!requestedRepair}
                      onClick={() => handleOpenCheckModal('repair-complete')}
                    >
                      {t("CompleteRepair")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-wrapper">
              <div className="detail-padding">
                <div style={{ display: 'flex' }}>
                  <div className="detail-item">
                    <Typography variant="modal3">{robotName}</Typography>
                    <Typography>{t("RobotName")}</Typography>
                  </div>

                  <div className="detail-item">
                    <Typography variant="modal3">53 hours</Typography>
                    <Typography>{t("Runtime")}</Typography>
                  </div>
                  <div className="detail-item">
                    <Typography variant="modal3">92%</Typography>
                    <Typography>{t("BatteryCapacity")}</Typography>
                  </div>
                  <div className="detail-item">
                    <span style={{ pointerEvents: 'none' }}>
                      <Button
                        variant="contained"
                        color="lightOrange"
                        disableElevation
                        sx={{ width: '92px', height: '36px' }}
                      >
                        {t("Paused")}
                      </Button>
                    </span>
                  </div>
                </div>
                {/* Notifications */}
                <div className="notification-battery">
                  <div className="notification-item-icons">
                    <BatteryCharging20 fontSize="large" />
                  </div>
                  <div className="notification-item">
                    <Typography variant="modal4">{t("Lowbattery")}</Typography>
                  </div>
                  <div className="notification-item">
                    <Typography>{batteryInfo}</Typography>
                    <Typography variant="modal1">{t("ChangeBattery")}</Typography>
                  </div>
                </div>
                <div className="notification-error">
                  <div className="notification-item-icons">
                    <Error fontSize="large" />
                  </div>
                  <div className="notification-item">
                    <Typography variant="modal4">Error 2!</Typography>
                  </div>
                  <div className="notification-item">
                    <Typography>Error 2: Debris extractors stuck</Typography>
                    <Typography variant="modal1">
                      Clear Roomba's debris extractors, then press CLEAN to restart
                    </Typography>
                  </div>
                </div>
                <div className="buttons-wrapper">
                  <div>
                    <Button variant="contained" sx={{ mr: '6px' }} onClick={handleNewBattery}>
                      {t("New Battery")}
                    </Button>
                    <Button variant="contained" sx={{ m: '0px 6px' }} onClick={handleBiT}>
                      {t("BiT")}
                    </Button>
                  </div>
                  <div>
                    <Button variant="contained" sx={{ m: '0px 6px' }} onClick={onReportError}>
                      {t("ReportError")}
                    </Button>
                    <Button
                      variant="contained"
                      color="greyGreen"
                      sx={{ m: '0px 6px' }}
                      onClick={() => onRepairRobot(robotName)}
                    >
                      {t("RepairRobot")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <RequestRepairModal
        open={openRequestRepairModal}
        handleClose={handleCloseModals}
        handleRequestRepair={handleRequestRepair}
      />
      <SimpleCheckModal
        open={openCheckModal}
        handleClose={handleCloseModals}
        title={modalTitle}
        type={modalType}
        handleCheck={handleCheck}
      />
    </div>
  )
})

export default WorkstationTestbedDetail
