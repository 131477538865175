export const TestBedTrackData = [
  {
    location: '1.A4',
    code: 'G3241',
    status: 'Ready',
    rut: 'DD-EP2a-15',
  },
  {
    location: '1.B3',
    code: 'G3905',
    status: 'Ready',
  },
  {
    location: '1.B5',
    code: 'F3905',
    status: 'Needs Cleaning',
    rut: 'DD-EP2b-14',
  },
  {
    location: '4.B4',
    code: 'C3925',
    status: 'Needs Repair',
  },
  {
    location: '1.A4',
    code: 'E3505',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G2105',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3515',
    status: 'Needs Repair',
  },
  {
    location: '1.A4',
    code: 'G3115',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3985',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3907',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3901',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3903',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'E1205',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G2135',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3095',
    status: 'Needs Repair',
  },
  {
    location: '1.A4',
    code: 'G3535',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G1235',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3217',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3001',
    status: 'Ready',
  },
  {
    location: '1.A4',
    code: 'G3003',
    status: 'Ready',
  },
]

export const RobotTrackData = [
  {
    name: 'Sapphire-EP1-0389',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'In Progress',
  },
  {
    name: 'Sapphire-EP1-0189',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'In Progress',
  },
  {
    name: 'Sapphire-EP1-0589',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'In Progress',
  },
  {
    name: 'Sapphire-EP1-0789',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'Defined',
  },
  {
    name: 'Sapphire-EP1-0889',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'Defined',
  },
  {
    name: 'Sapphire-EP1-0989',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'On Hold',
  },
  {
    name: 'Sapphire-EP1-0319',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'Defined',
  },
  {
    name: 'Sapphire-EP1-0329',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'In Review',
  },
  {
    name: 'Sapphire-EP1-0339',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'Ready for Investigation',
  },
  {
    name: 'Sapphire-EP1-0349',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'In Progress',
  },
  {
    name: 'Sapphire-EP1-0359',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'On Hold',
  },
  {
    name: 'Sapphire-EP1-0369',
    test_name: 'Sapphire EP1 EVAL',
    test_bed: 'A5-2',
    status: 'Defined',
  },
]

export const TestBedDetail = [
  {
    date: '2018-09-17T12:11:00.000Z',
    status: 'Test Started',
    user: 'A. Belkin',
  },
  {
    date: '2018-09-16T12:11:00.000Z',
    status: 'Cleaned',
    user: 'A. Belkin',
  },
  {
    date: '2018-08-14T12:11:00.000Z',
    status: 'Repaired',
    user: 'Q. Willard',
  },
  {
    date: '2018-08-13T12:11:00.000Z',
    status: 'Repair Requested',
    user: 'A. Belkin',
  },
  {
    date: '2018-06-16T12:11:00.000Z',
    status: 'Test Started',
    user: 'B. Johnston',
  },
  {
    date: '2018-06-15T12:11:00.000Z',
    status: 'Cleaned',
    user: 'A. Belkin',
  },
]

export const RobotDetailData = [
  {
    name: 'DD-EP2a-07',
    serial: 'S910029J200611E103184',
    blid: '2047651094851348650197',
    status: 'Running',
    battery: '90%',
    runtime: '83',
    missions: '54',
    errors: '12',
  },
]

export const RobotComponents = [
  {
    module: 'Wheel Module, Left',
    component: 'WM Motor',
    supplier: 'Jabil',
    serial: '491046199',
  },
  {
    module: 'Wheel Module, Right',
    component: 'WM Motor',
    supplier: 'Jabil',
    serial: '490046199',
  },
]

export const RobotBitResults = [
  {
    date: '2019-06-16T12:11:00.000Z',
    status: 'Pass',
  },
  {
    date: '2019-06-18T12:11:00.000Z',
    status: 'Pass',
  },
]

export const ComponentsData = [
  {
    id: 1,
    component: 'Wheel Module, Left',
    subcomponent: 'WM Motor',
    supplier: 'Jabil',
    serial: '491046199853',
  },
  {
    id: 2,
    component: 'Wheel Module, Right',
    subcomponent: 'WM Motor',
    supplier: 'Jabil',
    serial: '491041199852',
  },
  {
    id: 3,
    component: 'Wheel Module, Left',
    subcomponent: 'WM Motor',
    supplier: 'Jabil',
    serial: '491048199851',
  },
  {
    id: 4,
    component: 'Wheel Module, Right',
    subcomponent: 'WM Motor',
    supplier: 'Jabil',
    serial: '451041199854',
  },
]

export const WorkstationOverviewData = {
  rackA: [
    {
      location: '1.A5',
      status: 'Running',
      battery: 98,
      notifications: '',
    },
    {
      location: '1.A4',
      status: 'Running',
      battery: 78,
      notifications: 'User',
    },
    {
      location: '1.A3',
      status: 'Paused',
      battery: 51,
      notifications: 'Error 2',
    },
    {
      location: '1.A2',
      status: 'Stopped',
      battery: 24,
      notifications: '',
    },
    {
      location: '1.A1',
      status: 'Running',
      battery: 11,
      notifications: '',
    },
  ],
  rackB: [
    {
      location: '1.B5',
      status: 'Paused',
      battery: 98,
      notifications: 'BiT',
    },
    {
      location: '1.B4',
      status: 'Stopped',
      battery: 78,
      notifications: 'Repair',
    },
    {
      location: '1.B3',
      status: 'Running',
      battery: 51,
      notifications: '',
    },
    {
      location: '1.B2',
      status: 'Paused',
      battery: 2,
      notifications: 'Battery',
    },
    {
      location: '1.B1',
      status: 'Running',
      battery: 11,
      notifications: '',
    },
  ],
  rackC: [
    {
      location: '1.C5',
      status: 'Paused',
      battery: 1,
      notifications: 'Battery',
    },
    {
      location: '1.C4',
      status: 'Running',
      battery: 78,
      notifications: '',
    },
    {
      location: '1.C3',
      status: 'Running',
      battery: 51,
      notifications: '',
    },
    {
      location: '1.C2',
      status: 'Running',
      battery: 24,
      notifications: '',
    },
    {
      location: '1.C1',
      status: '',
      battery: null,
      notifications: '',
    },
  ],
  rackD: [
    {
      location: '1.D5',
      status: 'Running',
      battery: 98,
      notifications: '',
    },
    {
      location: '1.D4',
      status: 'Paused',
      battery: 78,
      notifications: 'User',
    },
    {
      location: '1.D3',
      status: 'Stopped',
      battery: 51,
      notifications: 'Error 2',
    },
    {
      location: '1.D2',
      status: 'Running',
      battery: 24,
      notifications: '',
    },
    {
      location: '1.D1',
      status: 'Paused',
      battery: 1,
      notifications: 'Battery',
    },
  ],
}

export const ErrorData = [
  {
    type: 'error2',
    title: 'Robot',
    description: 'Blah blah something about robot error.',
  },
  {
    type: 'battery',
    title: 'Battery',
    description: 'Blah blah something about battery error.',
  },
  {
    type: 'testbed',
    title: 'Test bed',
    description: 'Test bed error report',
  },
]

export const CompletedTraining = [
  {
    title: 'Training robot 1',
    completed: '2021-08-14T12:11:00.000Z',
    exp_date: '2021-08-14T12:11:00.000Z',
  },
  {
    title: 'Training robot 2 - examplewith 2 row title',
    completed: '2021-08-16T12:11:00.000Z',
    exp_date: '2021-08-16T12:11:00.000Z',
  },
]

export const NotCompletedTraining = [
  {
    title: 'Training 3',
    progress: 'Not started',
    due: '2022-08-14T12:11:00.000Z',
  },
  {
    title: 'Training 4 v1.3',
    progress: 'Updated/Not started',
    due: '2022-08-14T12:11:00.000Z',
  },
]

export const ActivityHistoryData = [
  {
    id: 1,
    type: 'Test',
    description: 'Started test',
    id_name: 'A3425',
    date_time: '2022-08-14T12:11:00.000Z',
  },
  {
    id: 2,
    type: 'Robot',
    description: 'Repair completed',
    id_name: 'Magnus-EP2-15',
    date_time: '2022-08-15T12:11:00.000Z',
  },
  {
    id: 3,
    type: 'Robot',
    description: 'Reported error',
    id_name: 'Magnus-EP2-15',
    date_time: '2022-08-16T12:11:00.000Z',
  },
  {
    id: 4,
    type: 'Battery',
    description: 'Added a new battery',
    id_name: 'Magnus-EP2-15',
    date_time: '2022-08-15T13:11:00.000Z',
  },
  {
    id: 5,
    type: 'Test Bed',
    description: 'Started test',
    id_name: 'A3425',
    date_time: '2022-08-16T12:11:00.000Z',
  },
  {
    id: 6,
    type: 'Test',
    description: 'Started test',
    id_name: 'A3425',
    date_time: '2022-08-14T12:11:00.000Z',
  },
  {
    id: 7,
    type: 'Cleaning',
    description: 'Cleaned',
    id_name: 'Magnus-EP2-15',
    date_time: '2022-08-14T12:11:00.000Z',
  },
  {
    id: 8,
    type: 'Repairs',
    description: 'Started repair on',
    id_name: 'Magnus-EP2-15',
    date_time: '2022-08-14T12:11:00.000Z',
  },
]

export const RepairOverviewData = [
  {
    robot_name: 'Sapphire-EP1-0389',
    error_name: 'Error 2',
    date_reported: '2022-08-13T12:11:00.000Z',
    assignee: 'G. Hollands',
  },
  {
    robot_name: 'Sapphire-EP1-0189',
    error_name: 'Stuck wheel',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0589',
    error_name: 'Error 34',
    date_reported: '2022-08-11T12:11:00.000Z',
    assignee: 'R. Dupont',
  },
  {
    robot_name: 'Sapphire-EP1-0789',
    error_name: 'Error 16',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: 'J. Liner',
  },
  {
    robot_name: 'Sapphire-EP1-0889',
    error_name: 'Motor noise',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0989',
    error_name: 'Error 73',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0319',
    error_name: 'Cracked bumper',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0329',
    error_name: 'Error 5',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0339',
    error_name: 'Error 53',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0349',
    error_name: 'Error 2',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0359',
    error_name: 'Error 2',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
  {
    robot_name: 'Sapphire-EP1-0369',
    error_name: 'Error 2',
    date_reported: '2022-08-14T12:11:00.000Z',
    assignee: '',
  },
]

export const MyRepairsData = [
  {
    robot_name: 'Sapphire-EP1-0389',
    error_name: 'Error 2',
    date_reported: '2022-08-14T12:11:00.000Z',
  },
  {
    robot_name: 'Sapphire-EP1-0189',
    error_name: 'Stuck wheel',
    date_reported: '2022-08-14T12:11:00.000Z',
  },
  {
    robot_name: 'Sapphire-EP1-0589',
    error_name: 'Error 34',
    date_reported: '2022-08-14T12:11:00.000Z',
  },
  {
    robot_name: 'Sapphire-EP1-0789',
    error_name: 'Error 16',
    date_reported: '2022-08-14T12:11:00.000Z',
  },
]
