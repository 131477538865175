import React from 'react'
import { Backdrop, Modal, Fade, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '709px',
    // height: '210px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const SimpleCheckModal = ({ open, handleClose, title, type, handleCheck }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleOk = () => {
    // Add API call for adding a comment on requested repair
    handleCheck(type)
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={(event, reason) => handleClose(event, reason)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '5px', marginBottom: '18.5px' }}
            >
              {title}
            </Typography>
            <div className="settings-content">
              <div className="button-wrapper">
                <Button onClick={handleClose} variant="contained" color="greyGreen" sx={{ m: '0px 6px' }}>
                  {t('Cancel')}
                </Button>
                <Button variant="contained" sx={{ m: '0px 6px' }} onClick={handleOk}>
                  {t('OK')}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default SimpleCheckModal
