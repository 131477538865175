import { makeAutoObservable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

class TestingDataStore {
  requestedCleaning = false
  requestedRepair = false
  repairComment = ''

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'TestingDataStore',
      properties: ['requestedCleaning', 'requestedRepair', 'repairComment'],
      storage: window.localStorage,
    })
  }

  setRequestedCleaning = isRequested => {
    this.requestedCleaning = isRequested
  }
  setRequestedRepair = isRequested => {
    this.requestedRepair = isRequested
  }
  setRepairComment = comment => {
    this.repairComment = comment
  }

  clearTestingDataStore = () => {
    this.requestedCleaning = false
    this.requestedRepair = false
    this.repairComment = ''

    clearPersistedStore(this)
  }
}

export default TestingDataStore
