import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button } from '@mui/material'
import './AddRobot.css'
import { useTranslation } from 'react-i18next'

const AddRobot = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleNext = () => {
    history.push(window.location.pathname + '/process')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('OnboardingReadiness')}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px' }}>
            <Typography variant="modal2" gutterBottom>
              {t('PriorToOnboarding')}:
            </Typography>
            <div className="instructions-list">
              <Typography variant="modal2" sx={{ minWidth: '365px' }}>
                • {t('RobotconnectedtoWiFi')}
              </Typography>
              <Typography variant="modal2" sx={{ minWidth: '365px' }}>
                • {t('Robotnamedcorrectly')}
              </Typography>
              <Typography variant="modal2" sx={{ minWidth: '365px' }}>
                • {t('BiTResults')}
              </Typography>
              <Typography variant="modal2" sx={{ minWidth: '365px' }}>
                • {t('ListOfModules')}
              </Typography>
            </div>
            <div className="button-wrapper">
              <Button variant="contained" onClick={handleNext}>
                {t('Next')}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}

export default AddRobot
