import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Button, Container, Paper, Typography } from '@mui/material'
import ComponentStep from '../Onboarding/Robot Onboarding/ComponentsStep'

const RepairRobot = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { robot_name } = useParams()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleResolved = () => {
    history.goBack()
  }

  const handleUnresolved = () => {
    history.goBack()
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {robot_name}
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Paper sx={{ p: '10px' }}>
          <div className="title-wrapper">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
              Robot:
            </Typography>
            <Typography variant="subtitle2">{robot_name}</Typography>
          </div>
          <ComponentStep fromTests={true} />
          <div className="addon-button-wrapper">
            <Button variant="contained" onClick={handleCancel} color="alertPurple" sx={{ m: '0px 6px' }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleUnresolved} color="greyGreen" sx={{ m: '0px 6px' }}>
              Save (unresolved)
            </Button>
            <Button variant="contained" onClick={handleResolved} sx={{ m: '0px 6px' }}>
              Save (resolved)
            </Button>
          </div>
        </Paper>
      </Container>
    </Box>
  )
}

export default RepairRobot
