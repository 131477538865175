import React, { useState, useEffect } from 'react'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import { Modal, Backdrop, Fade, Typography, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import './SettingsNotSavedModal.css'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '609px',
    height: '258px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const SettingsNotSavedModal = ({ isBlocked, errorMessage, handleSaveChanges }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState(location)
  const [shouldUnload, setShouldUnload] = useState(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()


  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true)
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, history])

  // Block non-react routes
  useEffect(() => {
    const unload = event => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = errorMessage // error message from modal
      }
      if (shouldUnload) {
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', unload)

    return () => window.removeEventListener('beforeunload', unload)
  }, [isBlocked, errorMessage, shouldUnload])

  const closeModal = () => {
    setIsModalOpen(false)
    setShouldUnload(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const showModal = nextLocation => {
    openModal()
    setLastLocation(nextLocation)
  }

  const handleBlockedRoute = nextLocation => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = async () => {
    // Save changed settings and go to the next screen
    setLoading(true)
    const isSaved = await handleSaveChanges()
    if (isSaved) {
      setLoading(false)
      closeModal()
      setConfirmedNavigation(true)
    } else {
      // else add some kind of error that it's not saved
      setLoading(false)
    }
  }

  const saveButton = t("SaveAndExit")
  // const modalTitle = error ? 'Error occurred while saving' : 'Settings not Saved'
  // const errorColor = error ? '#D32F2F' : '#6CB86A'

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={closeModal}>
                <Close />
              </Button>
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '5px', marginBottom: '18.5px' }}
            >
              {t("Settingsnotsaved")}
            </Typography>
            <div className="settings-content">
              <Typography>{errorMessage}</Typography>
              <div className="button-wrapper">
                <Button onClick={closeModal} variant="contained" color="greyGreen" sx={{ m: '0px 6px' }}>
                  {t("Goback")}
                </Button>
                <LoadingButton
                  onClick={handleConfirmNavigationClick}
                  loading={loading}
                  variant="contained"
                  sx={{ m: '0px 6px' }}
                >
                  {saveButton}
                </LoadingButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default SettingsNotSavedModal
