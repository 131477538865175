import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useOktaAuth } from '@okta/okta-react'
import {
  Typography,
  Grid,
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import Store from '../../../../Store/Store'
import client from '../../../../Config/axiosClient'

const RobotInfoStep = observer(({ getTest, getRobot, getSerial, handleContinue, handleBlock }) => {
  const { authState } = useOktaAuth()
  const [test, setTest] = useState('')
  const [robotName, setRobotName] = useState('')
  const [serial, setSerial] = useState('')
  const [testData, setTestData] = useState([])
  const [error, setError] = useState({
    testError: false,
    robotError: false,
    serialError: false,
  })
  const [robotData, setRobotData] = useState([])
  const [fetchingTests, setFetchingTests] = useState(false)
  const [fetchingRobots, setFetchingRobots] = useState(false)
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()

  useEffect(() => {
    const getTests = async () => {
      setFetchingTests(true)
      const res = await client.get(`/test`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setTestData(res.data.issues)
      setFetchingTests(false)
      if (Store.onboardingStore.test) {
        setTest(Store.onboardingStore.test)
      }
    }

    if (idToken) {
      getTests()
    }

    if (Store.onboardingStore.robotData.RobotSN) {
      setSerial(Store.onboardingStore.robotData.RobotSN)
    }
  }, [idToken])

  useEffect(() => {
    const getRobots = async () => {
      setFetchingRobots(true)
      const res = await client.get(`/robot?test_id=${Store.onboardingStore.selectedTest.id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setRobotData(res.data.tests)
      setFetchingRobots(false)
      if (Store.onboardingStore.selectedRobot.summary) {
        setRobotName(Store.onboardingStore.selectedRobot.summary)
      }
    }
    if (idToken && test) {
      getRobots()
    }
  }, [idToken, test])

  useEffect(() => {
    if (test && robotName && serial) {
      handleBlock(true)
      handleContinue(false)
    } else {
      handleContinue(true)
    }
  }, [test, robotName, serial, handleContinue, handleBlock])

  const handleTestChange = event => {
    handleBlock(true)
    setTest(event.target.value)
    getTest(event.target.value)
    if (event.target.value !== Store.onboardingStore.selectedTest.summary) {
      Store.onboardingStore.setRobotInfoSelectedRobot('')
      setRobotName('')
    }
    Store.onboardingStore.setRobotInfoSelectedTest(testData.find(test => test.summary === event.target.value))
    Store.onboardingStore.setRobotInfoTest(event.target.value)
    // handleError(event)
  }

  const handleRobotNameChange = event => {
    setRobotName(event.target.value)
    getRobot(event.target.value)
    Store.onboardingStore.setRobotInfoSelectedRobot(robotData.find(robot => robot.summary === event.target.value))
  }

  const handleInputChange = event => {
    setSerial(event.target.value)
    getSerial(event.target.value)
    handleError(event, 'serial-select')
  }

  const handleError = (event, from) => {
    // childElementCount can be either 0 or 1
    // console.log('Child elements ', event.target.childElementCount)
    if (from === 'test-select' && !test) {
      const errorType = 'testError'
      checkValidation(event, errorType)
    } else if (from === 'robot-select' && !robotName) {
      const errorType = 'robotError'
      checkValidation(event, errorType)
    } else if (from === 'serial-select') {
      const errorType = 'serialError'
      checkValidation(event, errorType)
    }
  }

  const checkValidation = (event, errorType) => {
    if (errorType === 'serialError') {
      if (event.target.value !== '') {
        setError({ ...error, [errorType]: false })
      } else {
        setError({ ...error, [errorType]: true })
      }
    } else {
      if (event.target.childElementCount === 0) {
        setError({ ...error, [errorType]: true })
      } else {
        setError({ ...error, [errorType]: false })
      }
    }
  }

  return (
    <Grid container direction="column" sx={{ p: '40px 40px 0px' }}>
      <Typography variant="modal1" align="center" gutterBottom>
        {t("SelectTestAndRobot")}
      </Typography>
      <Typography variant="modal1" align="center" gutterBottom>
        {t("ScanRobotSerial")}
      </Typography>
      <Typography variant="modal1" align="center" gutterBottom sx={{ mb: 4 }}>
        {t("RobotNameMustMatch")}
      </Typography>
      <div className="title-field">
        <Typography variant="subtitle1" component="div">
          {t("Test")}
        </Typography>
        <Box sx={{ width: '320px' }}>
          <FormControl fullWidth required={fetchingTests ? false : true} error={error.testError}>
            {fetchingTests ? (
              <InputLabel id="demo-simple-select-label">
                <CircularProgress size={25} />
              </InputLabel>
            ) : (
              <InputLabel id="demo-simple-select-label">Test</InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="test-select"
              value={test}
              label="Test"
              onChange={handleTestChange}
              onClose={event => handleError(event, 'test-select')}
              disabled={fetchingTests}
              MenuProps={{ PaperProps: { sx: { maxHeight: 200, maxWidth: '320px' } } }}
            >
              {testData.map(test => {
                return (
                  <MenuItem key={test.id} value={test.summary}>
                    {test.summary}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="title-field">
        <Typography variant="subtitle1" component="div">
          {t("RobotName")}
        </Typography>
        <Box sx={{ width: '320px' }}>
          <FormControl fullWidth required={fetchingRobots ? false : true} error={error.robotError}>
            {fetchingRobots ? (
              <InputLabel id="demo-simple-select-label">
                <CircularProgress size={25} />
              </InputLabel>
            ) : (
              <InputLabel id="demo-simple-select-label">{t("Name")}</InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="robot-select"
              key={Store.onboardingStore.selectedTest.id}
              value={robotName}
              label="Name"
              onChange={handleRobotNameChange}
              onClose={event => handleError(event, 'robot-select')}
              disabled={fetchingRobots || !test}
              MenuProps={{ PaperProps: { sx: { maxHeight: 200, maxWidth: '320px' } } }}
            >
              {robotData.map(robot => {
                return (
                  <MenuItem key={robot.id} value={robot.summary}>
                    {robot.summary}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="title-field">
        <Typography variant="subtitle1" component="div">
          {t("RobotSN")}
        </Typography>
        <Box sx={{ width: '320px' }}>
          <FormControl fullWidth>
            <TextField
              id="serial"
              label="Enter Serial Number"
              required
              value={serial}
              error={error.serialError}
              onChange={handleInputChange}
              onBlur={event => handleError(event, 'serial-select')}
            />
          </FormControl>
        </Box>
      </div>
    </Grid>
  )
})

export default RobotInfoStep
