import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

const RobotOnboardSuccess = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("Success")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px', m: '10px' }}>
            <Typography variant="modal1" align="center" gutterBottom>
              {t("RobotOnboardSuccess")}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', margin: '9px 35px', marginTop: '26px' }}>
              <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
                {t("RobotName")}
              </Typography>
              <Typography variant="modal2" component="div">
                XXXXXXXXXXXXXXXXXX
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '9px 35px' }}>
              <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
                {t("Serialnumber")}
              </Typography>
              <Typography variant="modal2" component="div">
                YYYYYYYYYYYYYYYYYYYY
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '9px 35px', marginTop: '26px' }}>
              <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
                {t("Model")}
              </Typography>
              <Typography variant="modal2" component="div">
                Roomba i7
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '9px 35px' }}>
              <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
                {t("Software")}
              </Typography>
              <Typography variant="modal2" component="div">
                2.4.6-3
              </Typography>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}

export default RobotOnboardSuccess
