import React, { useState, useEffect } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

import { makeStyles } from '@mui/styles'
import { TestBedTrackData } from '../../../../Store/MOCK_DATA'
import CustomSearch from '../../../Search/Search'
import Store from '../../../../Store/Store'
import client from '../../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  headtable: {
    '& .MuiTableCell-head': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 20%)',
    },
  },
})

const TrackTable = observer(() => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    Ready: false,
    'Needs Cleaning': false,
    'Needs Repair': false,
    'Under Test': false,
  })
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('location')
  const [data, setData] = useState([])
  const { authState } = useOktaAuth()
  const classes = useStyles()
  const { t } = useTranslation()

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  // Axios working example
  useEffect(() => {
    // 1.
    const getData = async () => {
      const res = await client.get('/testbed', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setData(res.data)
    }
    getData()
  }, [idToken])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClick = (event, row) => {
    Store.rowData.resetRowData()
    Store.rowData.setRowData(row)
    history.push('/app/testbed/id')
  }

  // Data from API
  const testbedRows = data
  console.log(testbedRows)

  // MOCK DATA
  let rows = TestBedTrackData

  const columns = [
    { id: 'location', label: t("Location"), minWidth: 170 },
    { id: 'code', label: t("TestBedNoBreak"), minWidth: 170 },
    {
      id: 'status',
      label: t("Status"),
      minWidth: 170,
    },
  ]

  const getStatusColor = status => {
    if (status === 'Ready') {
      return '#6cb86a'
    } else if (status === 'Needs Repair') {
      return '#AE72A3'
    } else if (status === 'Needs Cleaning') {
      return '#6A768C'
    } else {
      return 'black'
    }
  }

  const setTextBold = status => {
    if (status === 'Ready' || status === 'Needs Repair' || status === 'Needs Cleaning') {
      return 'bold'
    } else {
      return 'normal'
    }
  }

  const onFilterChanged = name => event => {
    setFilters({ ...filters, [name]: event.target.checked })
  }

  const filterArray = Object.keys(filters)
  const filteredRows = rows.filter(row => {
    const filterKeyTrue = filterArray.some(filterKey => {
      return filterKey === row.status && filters[filterKey] === true
    })
    if (filterKeyTrue) {
      return row
    } else {
      return null
    }
  })

  if (filteredRows.length > 0) {
    rows = filteredRows
  }

  function handleSearchUpdate(value) {
    setSearch(value)
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Paper sx={{ p: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: '8px' }}>
          <Grid container direction="row" columnSpacing={3} alignItems="center" sx={{ ml: '0px', mt: '3px' }}>
            <Grid item sx={{ mr: 3 }}>
              <Typography>{t("Filterbystatus")}:</Typography>
            </Grid>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={filters['Under Test']}
                      onChange={onFilterChanged('Under Test')}
                      value="Under Test"
                    />
                  }
                  label={t("UnderTest")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={<Checkbox checked={filters['Ready']} onChange={onFilterChanged('Ready')} value="Ready" />}
                  label={t("Ready")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={filters['Needs Cleaning']}
                      onChange={onFilterChanged('Needs Cleaning')}
                      value="Needs Cleaning"
                    />
                  }
                  label={t("NeedsCleaning")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={filters['Needs Repair']}
                      onChange={onFilterChanged('Needs Repair')}
                      value="Needs Repair"
                    />
                  }
                  label={t("NeedsRepair")}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <div style={{ width: '370px' }}>
            <CustomSearch search={search} handleSearchUpdate={handleSearchUpdate} />
          </div>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3 }}>
          <TableContainer sx={{ maxHeight: '100%' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.headtable}>
                  {columns.map(column => (
                    <TableCell
                      sortDirection={orderBy === column.id ? order : false}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter(row => {
                    if (search === '') {
                      return row
                    }
                    return (
                      row.status.toLowerCase().includes(search.toLowerCase()) ||
                      row.location.toLowerCase().includes(search.toLowerCase()) ||
                      row.code.toLowerCase().includes(search.toLowerCase())
                    )
                  })
                  .map(row => {
                    let statusColor = ''
                    let boldText = ''
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        onClick={event => handleClick(event, row)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {columns.map(column => {
                          const value = row[column.id]
                          statusColor = getStatusColor(value)
                          boldText = setTextBold(value)
                          return (
                            <TableCell key={column.id} sx={{ color: `${statusColor}`, fontWeight: `${boldText}` }}>
                              {value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Paper>
    </Box>
  )
})

export default TrackTable
