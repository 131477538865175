import React from 'react'
import { observer } from 'mobx-react'
import Store from '../../../Store/Store'
import { Box, Typography, Container, Paper, Grid, Snackbar, Alert, Slide } from '@mui/material'
import { WorkstationOverviewData } from '../../../Store/MOCK_DATA'
import RackTable from './Tables/RackTable'

const WorkstationOverview = observer(() => {
  const title = Store.workstationName

  const rackA = WorkstationOverviewData['rackA']
  const rackB = WorkstationOverviewData['rackB']
  const rackC = WorkstationOverviewData['rackC']
  const rackD = WorkstationOverviewData['rackD']

  function slideTransition(props) {
    return <Slide {...props} direction="left" />
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    Store.setOpenSnackbar(false)
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {title}
      </Typography>
      <Container maxWidth="lg" align="center" sx={{ mt: 4 }}>
        <Grid container spacing={4} direction="row" sx={{ marginLeft: '0px' }}>
          <Grid item>
            <Paper>
              <RackTable rows={rackA} />
            </Paper>
          </Grid>
          <Grid item>
            <Paper>
              <RackTable rows={rackB} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={4} direction="row" sx={{ marginLeft: '0px', marginTop: '0px' }}>
          <Grid item>
            <Paper>
              <RackTable rows={rackC} />
            </Paper>
          </Grid>
          <Grid item>
            <Paper>
              <RackTable rows={rackD} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={Store.openSnackbar}
        TransitionComponent={slideTransition}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: '55px', mr: '10px' }}
      >
        <Alert onClose={handleClose} severity={Store.severity}>
          {Store.message}
        </Alert>
      </Snackbar>
    </Box>
  )
})

export default WorkstationOverview
