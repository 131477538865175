import React from 'react'
import { observer } from 'mobx-react'
import { Typography, Grid } from '@mui/material'
import './SuccessStep.css'
import Store from '../../../../Store/Store'
import { useTranslation } from 'react-i18next'


const SuccessStep = observer(() => {
  // Current robot data with new serial number don't have robot name
  // We will use Jira robot name instead
  const robotData = Store.onboardingStore.robotData
  const selectedRobotData = Store.onboardingStore.selectedRobot
  const { t } = useTranslation()

  return (
    <div>
      <Grid container direction="column" sx={{ p: '40px 40px 0px' }}>
        <Typography variant="modal1" align="center" gutterBottom color="#6CB86A">
          {t("RobotOnboardingSuccess")}
        </Typography>
        <div className="success-text-container-top">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t("RobotName")}
          </Typography>
          <Typography variant="modal2" component="div">
            {selectedRobotData.summary}
          </Typography>
        </div>
        <div className="success-text-container-bottom">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t("Serialnumber")}
          </Typography>
          <Typography variant="modal2" component="div">
            {robotData.RobotSN}
          </Typography>
        </div>
        <div className="success-text-container-top">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t("Model")}
          </Typography>
          <Typography variant="modal2" component="div">
            {robotData.ModelName}
          </Typography>
        </div>
        <div className="success-text-container-bottom">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t("Software")}
          </Typography>
          <Typography variant="modal2" component="div">
            2.4.6-3
          </Typography>
        </div>
      </Grid>
    </div>
  )
})

export default SuccessStep
