import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, Snackbar, Alert, FormControl, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import './StartRobotTest.css'
import { observer } from 'mobx-react'
import Store from '../../../Store/Store'
import client from '../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'

const StartRobotTest = observer(() => {
  const [robot, setRobot] = useState('')
  const [battery, setBattery] = useState('')
  const [testBed, setTestBed] = useState('')
  const [loading, setLoading] = useState(false)
  const { authState } = useOktaAuth()
  const history = useHistory()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleRobotChange = value => {
    setRobot(value)
  }

  const handleBatteryChange = value => {
    setBattery(value)
  }

  const handleTestBedChange = value => {
    setTestBed(value)
  }

  const handleCancel = () => {
    history.push(history.location.state.from)
  }

  const handleStartTest = async () => {
    setLoading(true)
    const res = await client
      .post(
        '/test',
        {
          robot_sn: robot,
          battery_sn: battery,
          testbed_sn: testBed,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        console.log(error)
        setLoading(false)
        Store.setOpenSnackbar(true)
        // Severity types: success, error, warning, info
        Store.setSeverity('error')
        // Change this message later
        Store.setMessage('Error: Test not started')
      })

    if (res?.status === 200) {
      setLoading(false)
      Store.setOpenSnackbar(true)
      Store.setSeverity('success')
      Store.setMessage('Test started')
      history.push('/app/testing/workstation')
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    Store.setOpenSnackbar(false)
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("StartTest")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px' }}>
            <Typography variant="modal1" align="center" gutterBottom>
              {t("ScanRobotTestbed")}
            </Typography>
            <Grid container direction="column" justifyContent="space-evenly" sx={{ mt: '18px' }}>
              <div className="test-row">
                <Typography variant="subtitle1" component="div">
                  {t("Robot")}
                </Typography>
                <Box sx={{ minWidth: '360px' }}>
                  <FormControl fullWidth>
                    <TextField id="outlined" value={robot} onChange={e => handleRobotChange(e.target.value)} />
                  </FormControl>
                </Box>
              </div>
              <div className="test-row">
                <Typography variant="subtitle1" component="div">
                  {t("Battery")}
                </Typography>
                <Box sx={{ minWidth: '360px' }}>
                  <FormControl fullWidth>
                    <TextField id="outlined" value={battery} onChange={e => handleBatteryChange(e.target.value)} />
                  </FormControl>
                </Box>
              </div>
              <div className="test-row">
                <Typography variant="subtitle1" component="div">
                  {t("TestBed")}
                </Typography>
                <Box sx={{ minWidth: '360px' }}>
                  <FormControl fullWidth>
                    <TextField id="outlined" value={testBed} onChange={e => handleTestBedChange(e.target.value)} />
                  </FormControl>
                </Box>
              </div>
            </Grid>
            <div className="button-wrapper">
              <Button variant="contained" color="greyGreen" sx={{ m: '0px 6px' }} onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <LoadingButton onClick={handleStartTest} loading={loading} variant="contained" sx={{ m: '0px 6px' }}>
                {t("StartTest")}
              </LoadingButton>
            </div>
          </Grid>
        </Paper>
      </Container>
      <Snackbar
        open={Store.openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: '55px', mr: '10px' }}
      >
        <Alert onClose={handleClose} severity={Store.severity} sx={{ width: '100%' }}>
          {Store.message}
        </Alert>
      </Snackbar>
    </Box>
  )
})

export default StartRobotTest
