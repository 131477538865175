import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, FormControl, Select, MenuItem } from '@mui/material'
import { observer } from 'mobx-react'
import Store from '../../../Store/Store'
import './WorkstationSelection.css'
import { useTranslation } from 'react-i18next'

const WorkstationSelection = observer(() => {
  const [workstation, setWorkstation] = useState('Workstation 1')
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleWorkstationChange = e => {
    setWorkstation(e.target.value)
  }

  const onOkClick = () => {
    Store.setWorkstationName(workstation)
    history.push('/app/testing/workstation')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t("SelectWorkstation")}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>
          <Grid container direction="column" sx={{ p: '45px' }}>
            <div className="container-content-column">
              <Box sx={{ minWidth: '320px' }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={workstation}
                    onChange={handleWorkstationChange}
                  >
                    <MenuItem value={'Workstation 1'}>Workstation 1</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="button-wrapper">
              <Button variant="contained" onClick={onOkClick}>
                {t("Go")}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
})

export default WorkstationSelection
