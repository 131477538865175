import React from 'react'
import { useHistory } from 'react-router-dom'
import { MenuItem } from '@mui/material'
import './MenuItems.css'
import { useTranslation } from 'react-i18next'

const MenuItems = ({ title, popupState, width }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleRouteChange = route => {
    popupState.close()
    history.push(`${route}`)
  }

  if (title === 'Asset Tracking') {
    return (
      <>
        <MenuItem onClick={() => handleRouteChange('/app/testbed')} sx={{ width: `${width}px` }}>
          {t("Test Beds")}
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange('/app/robot')} sx={{ width: `${width}px` }}>
          {t("Robots")}
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange('/app/spare_part')} sx={{ width: `${width}px` }}>
          {t("SpareParts")}
        </MenuItem>
      </>
    )
  }

  if (title === 'Onboarding') {
    return (
      <>
        <MenuItem onClick={() => handleRouteChange('/app/onboarding/robot')} sx={{ width: `${width}px` }}>
          {t("AddRobot")}
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange('/app/onboarding/battery')} sx={{ width: `${width}px` }}>
          {t("AddBattery")}
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange('/app/onboarding/addon_tests')} sx={{ width: `${width}px` }}>
          {t("AddonTests")}
        </MenuItem>
      </>
    )
  }

  return <p>{t("Loading")}</p>
}

export default MenuItems
