import React, { useState } from 'react'
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Grid,
  Button,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'

import Store from '../../../../Store/Store'
import { TestBedDetail } from '../../../../Store/MOCK_DATA'
import './TestBedDetail.css'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  headtable: {
    '& .MuiTableCell-head': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 20%)',
    },
  },
})

const TestBedsDetail = observer(() => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const classes = useStyles()
  const rowData = Store.rowData.rowData
  const { t } = useTranslation()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const dateFormater = cell => {
    // const date = new Date(cell);
    // console.log(JSON.stringify(date));
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formatedDate
  }

  // MOCK DATA
  let rows = TestBedDetail

  const columns = [
    {
      id: 'date',
      label: t("Date"),
      minWidth: 170,
    },
    { id: 'status', label: 'Status', minWidth: 170 },
    {
      id: 'user',
      label: t("User"),
      minWidth: 170,
    },
  ]

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {rowData.location} - {rowData.code}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: '8px', height: '50px', alignItems: 'flex-start' }}>
            <div className="testbed-detail-row">
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
                {t("Status")}:
              </Typography>
              <Typography variant="subtitle2">{rowData.status}</Typography>
            </div>
            <div className="testbed-detail-row">
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
                {t("RUT")}:
              </Typography>
              <Typography variant="subtitle2">{rowData.rut}</Typography>
            </div>
          </Box>
          <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3 }}>
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.headtable}>
                    {columns.map(column => (
                      <TableCell
                        sortDirection={orderBy === column.id ? order : false}
                        key={column.id}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.date}>
                          {columns.map(column => {
                            let value = row[column.id]
                            if (column.id === 'date') {
                              value = dateFormater(value)
                            }
                            return <TableCell key={column.id}>{value}</TableCell>
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <Box sx={{ display: 'flex', flexDirection: 'row', mt: '8px', height: '50px' }}>
            <Grid container direction="row" justifyContent="left" alignItems="center" sx={{ mt: '6.5px' }}>
              <Grid item>
                <Button variant="contained" sx={{ m: '0px 12px' }}>
                  {t("RequestCleaning")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="greyGreen">
                  {t("CompleteCleaning")}
                </Button>
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="right" alignItems="center" sx={{ mt: '6.5px' }}>
              <Grid item>
                <Button variant="contained">{t("RequestRepair")}</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="greyGreen" sx={{ m: '0px 12px' }}>
                  {t("CompleteRepair")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
})

export default TestBedsDetail
